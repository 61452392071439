import React, { FC, useState } from "react";
import {
  ESituacaoMatricula,
  IAlunoPhotoDeltaId,
  IMatricula,
  utils,
} from "@deltasge/marauders-map";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import { Environment } from "configs";
import { IFilterDescritiva } from "interfaces";
import {
  ChevronDown as ExpandMoreIcon,
  ArrowRightThick as SituacaoIcon,
} from "mdi-material-ui";
import { Avatar } from "components/Avatar";

export const Filter: FC<{
  Component: () => JSX.Element;
  filter: IFilterDescritiva;
  matriculas: IMatricula[];
  handleFilterMatricula: (idAluno?: number) => void;
}> = ({ Component, matriculas, filter, handleFilterMatricula }) => {
  const [panel, setPanel] = useState(false);

  const matricula = matriculas.find((f) => f.aluno?.uid == filter.idAluno);

  return (
    <Box
      sx={{
        width: ({ spacing }) => spacing(Environment.DRAWER_WIDTH),
        display: "block",
      }}
    >
      <Component />
      <Accordion
        elevation={0}
        expanded={panel}
        onChange={() => setPanel(!panel)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Aluno
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {!filter.idAluno
                ? "TODOS OS ALUNOS"
                : (
                    matricula?.aluno?.nome || matricula?.aluno?.nomeusual
                  )?.rUpper()}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            <ListItemButton
              onClick={() => {
                handleFilterMatricula(undefined);
              }}
            >
              <ListItemIcon>
                <Radio checked={undefined == filter.idAluno} />
              </ListItemIcon>
              <ListItemText primary="TODOS OS ALUNOS" />
            </ListItemButton>
            {matriculas.map((m) => (
              <ListItemButton
                key={`mtricula_filter_${m.id}`}
                onClick={() => {
                  handleFilterMatricula(m.aluno?.uid);
                }}
              >
                <ListItemIcon>
                  <Radio checked={m.aluno?.uid == filter.idAluno} />
                </ListItemIcon>
                <ListItemAvatar>
                  <Badge
                    overlap="circular"
                    badgeContent={
                      !utils.matriculaAtiva(
                        m.situacao as ESituacaoMatricula
                      ) ? (
                        <SituacaoIcon color="secondary" />
                      ) : undefined
                    }
                  >
                    <Avatar
                      key={"matricula_aluno_avatar_" + m.id}
                      displayName={(
                        m.aluno?.nomeusual || m.aluno?.nome
                      )?.rUpper()}
                      src={
                        m.aluno?.imagemAluno?.caminhoArquivo ??
                        (m.aluno as IAlunoPhotoDeltaId).photoUrl
                      }
                    />
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  primary={`${m.nrChamada} - ${(
                    m.aluno?.nomeusual || m.aluno?.nome
                  )?.rUpper()}`}
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </Box>
  );
};
