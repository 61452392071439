import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { PushNotificationContext } from "context/PushNotificationContext";
import { Main } from "pages/_layouts/Main";
import React, { FC, useContext } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { setDevTools } from "store/modules/layout";

export const DevMode: FC = () => {
  const { devMode, devTools } = useAppSelector((state) => state.layout);

  if (!devMode) {
    return <Alert severity="error">O Modo Desenvolvedor esta desligado</Alert>;
  }

  const {
    isSupported,
    permission,
    token,
    requestPermission,
    requestPushToken,
    lastNotification,
  } = useContext(PushNotificationContext);

  const dispatch = useAppDispatch();

  return (
    <Main title="Modo Desenvolvedor">
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Testes Push Notification" />
            {!isSupported && (
              <CardContent>
                <Typography>
                  Se você não esta vendo nada aqui é porque seu dispositivo não
                  tem suporte a Push Notification
                </Typography>
              </CardContent>
            )}
            {isSupported && (
              <CardContent>
                <Typography variant="button">Push Token:</Typography>
                {token && <Typography variant="caption">{token}</Typography>}
                <br />
                <Typography variant="button">Permissão:</Typography>
                {permission && (
                  <Typography variant="caption">{permission}</Typography>
                )}
                <br />
                <Typography variant="button">Push Notification:</Typography>
                {lastNotification && (
                  <Typography variant="caption">
                    {JSON.stringify(lastNotification)}
                  </Typography>
                )}
              </CardContent>
            )}
            {isSupported && (
              <CardActions>
                <Button
                  size="small"
                  onClick={requestPermission}
                  variant="outlined"
                >
                  Permitir Notificações
                </Button>
                {permission == "granted" && (
                  <Button
                    size="small"
                    onClick={requestPushToken}
                    variant="outlined"
                  >
                    Get PushToken
                  </Button>
                )}
              </CardActions>
            )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Ferramentas"></CardHeader>
            <CardContent>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={devTools?.thumbnailBase64 || false}
                      onChange={({ target: { checked } }) =>
                        dispatch(setDevTools({ thumbnailBase64: checked }))
                      }
                    />
                  }
                  label="Criar cache do avatar no formato Base64"
                />
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Main>
  );
};
