import React, { FC, useEffect, useState } from "react";
import {
  Avatar as AvatarMUI,
  CircularProgress,
  SxProps,
  Theme,
} from "@mui/material";
import { loadS3File, urlToFile, resizeFile } from "utils";
import { utils } from "@deltasge/marauders-map";
import { cachePhotoUrl } from "configs";
import { useAppSelector } from "store";

export const Avatar: FC<{
  photoS3?: boolean | null;
  displayName?: string;
  src?: string | null;
  sx?: SxProps<Theme>;
  thumbnail?: boolean;
}> = ({ displayName, src: initSrc, sx, photoS3, thumbnail = true }) => {
  const [src, setSrc] = useState(initSrc);
  const [loading, setLoading] = useState(false);

  const devTools = useAppSelector((state) => state.layout.devTools);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (
          src &&
          src.indexOf("https") == -1 &&
          !utils.isBase64(src, { allowMime: true })
        ) {
          const { processed } = await loadS3File(src, true);
          if (processed) {
            if (utils.isBase64(processed, { allowMime: true })) {
              setSrc(processed);
            } else if (thumbnail) {
              const file = await urlToFile({ url: processed });
              const resized = await resizeFile(file, { max: 256 });
              const imageBase64 = await utils.fileToBase64(resized);
              if (typeof imageBase64 == "string") {
                setSrc(imageBase64);
                if (devTools?.thumbnailBase64) {
                  const cache24h = 60 * 24;
                  cachePhotoUrl.set(src, imageBase64, cache24h);
                }
              }
            } else setSrc(processed);
          }
        }
      } catch (error) {
        setSrc(null);
      } finally {
        setLoading(false);
      }
    })();
  }, [photoS3, src]);

  if (loading) {
    return (
      <AvatarMUI alt={displayName ?? "Usuário"}>
        <CircularProgress
          sx={({ spacing }) => ({
            width: `${spacing(3)} !important`,
            height: `${spacing(3)} !important`,
          })}
        />
      </AvatarMUI>
    );
  }

  if (src)
    return (
      <AvatarMUI
        sx={{
          ...sx,
          bgcolor: displayName ? displayName.toColor() : undefined,
        }}
        src={src}
        alt={displayName ?? "Usuário"}
      />
    );
  else if (displayName)
    return (
      <AvatarMUI
        sx={{
          ...sx,
        }}
      >
        {displayName.at(0)}
      </AvatarMUI>
    );
  return <AvatarMUI sx={sx} />;
};
