import React, { FC, useCallback, useEffect, useState } from "react";
import { clean as validation } from "store/modules/auth/validate";
import { useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  EAppType,
  ETypeService,
  IInstituicao,
  IUser,
} from "@deltasge/marauders-map";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import * as yup from "yup";
import { pt } from "yup-locale-pt";

import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
  debounce,
} from "@mui/material";
import {
  School as SchoolIcon,
  CloseCircleOutline as RemoveIcon,
  AccountCancel as AccountCancelIcon,
} from "mdi-material-ui";

import logo from "assets/images/logo.png";
import logoLogin from "assets/images/logo-login.png";
import * as styles from "assets/styles/login";
import { Image, Form } from "assets/styleds";

import { Environment, history } from "configs";

import { useAuthentication } from "context/AuthenticationProvider";

import { snack } from "components/GlobalSnackbar";

import { useAppDispatch } from "store";
import { set as setUser } from "store/modules/auth/usuario";

import { useApplyProps } from "hooks/useApplyProps";

import { getError } from "utils";
import { Password } from "components/Inputs/Password";
import { LoadingButton } from "@mui/lab";
import { ISocialUser } from "interfaces";
import { logEvent } from "firebase/analytics";
import { analytics } from "App";
import { useDialog } from "hooks/useDialog";
import { EsqueceuSenhaComponent } from "./components/EsqueceuSenhaComponent";

yup.setLocale(pt);
const objectForm = yup.object({
  usuario: yup.string().required().label("Usuário"),
  senha: yup.string().required().label("Senha"),
});
const schemaForm = objectForm.shape({});
type FormState = yup.InferType<typeof objectForm>;

export const Login: FC = () => {
  const [searching, setSearching] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [instituicaoText, setInstituicaoText] = useState<string | null>(null);
  const [instituicoes, setInstituicoes] = useState<IInstituicao[]>([]);
  const [carregandoInsituicoes, setCarregandoInsituicoes] = useState(false);
  const [carregandoUsuarioEmail, setCarregandoUsuarioEmail] = useState(false);
  const [instituicao, setInstituicao] = useState<IInstituicao | null>(null);
  const [usuarioEmail, setUsuarioEmail] = useState<IUser>();
  const [loginManual, setLoginManual] = useState(false);

  const location = useLocation();
  const { cliente } = useParams<{ cliente?: string }>();
  const dispatch = useAppDispatch();
  const {
    user: socialUser,
    GoogleButton,
    AppleButton,
    SocialUserComponent,
    signOut,
    signing,
  } = useAuthentication();

  const params = new URLSearchParams(location.search);
  const from = params.get("from") || "/";
  let codigoCliente: string | undefined;
  let pathCliente: string | undefined;
  if (cliente) {
    if (isNaN(parseInt(cliente, 10))) pathCliente = cliente;
    else codigoCliente = cliente;
  }

  let redir = params.get("redir");
  if (redir && !redir?.startsWith("/")) redir = "/" + redir;
  const paramInstituicao = params.get("instituicao");
  const token = params.get("token");
  const app = params.get("app");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormState>({
    resolver: yupResolver(schemaForm),
    defaultValues: {
      usuario: "",
      senha: "",
    },
  });

  const {
    show: handleOpenDialog,
    RenderDialog: EsqueceuSenhaDialog,
    hide: handleHideDialog,
  } = useDialog({
    title: "Recuperar senha",
    options: {
      maxWidth: "sm",
    },
  });

  const applyProps = useApplyProps<FormState>({
    register,
    errors,
    schema: schemaForm,
  });

  const onSubmit = async (payload: FormState) => {
    try {
      if (!instituicao) {
        throw new Error("Selecione uma instituição");
      }
      setSubmiting(true);
      const codigoZeroEsqueda = instituicao.codigo.toString().padStart(4, "0");
      let { data: usuarioApi } = await axios.post<IUser>(
        `${Environment.AUTH_API_URL}/${codigoZeroEsqueda}/auth/${EAppType.Educador}/`,
        payload
      );

      if (socialUser) {
        const payload: IUser = {
          ...usuarioApi,
          firebaseEmail: socialUser.email,
          firebaseId: socialUser.uid,
          displayName: socialUser.displayName,
          photoUrl: socialUser.photoURL,
        };
        const { data: usuarioConfig } = await axios.put<IUser>(
          `${Environment.CONFIG_DELTA_API_URL}/usuarios/usuario/${usuarioApi.deltaId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${usuarioApi.token}`,
            },
          }
        );
        usuarioApi = usuarioConfig;
      }

      await handleEntrar(usuarioApi);
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setSubmiting(false);
    }
  };

  const handleEntrar = useCallback(
    async (user: IUser) => {
      try {
        dispatch(validation());
        dispatch(setUser({ ...user }));
        snack.info(`Bem vindo(a) ${user.nome.rUpper()}!`);
        logEvent(analytics, "login", {
          method: user.firebaseId ? "Firebase" : "UsuarioSenha",
        });
        history.replace("/");
      } catch (error) {
        snack.error(getError(error));
      }
    },
    [dispatch, from, history]
  );

  const handleConectar = useCallback(
    async ({
      socialUser,
      usuario,
    }: {
      socialUser: ISocialUser;
      usuario: IUser;
    }) => {
      try {
        setSubmiting(true);
        const payload: IUser = {
          ...usuario,
          firebaseEmail: socialUser.email,
          firebaseId: socialUser.uid,
          displayName: socialUser.displayName,
          photoUrl: socialUser.photoURL,
        };
        const { data } = await axios.put<IUser>(
          `${Environment.CONFIG_DELTA_API_URL}/usuarios/usuario/${usuario.deltaId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${usuario.token}`,
            },
          }
        );
        await handleEntrar(data);
      } catch (error) {
        snack.error(getError(error));
      } finally {
        setSubmiting(false);
      }
    },
    [handleEntrar]
  );

  useEffect(() => {
    (async () => {
      try {
        if (socialUser && socialUser.email != null) {
          setSearching(true);
          const { data: usuarios } = await axios.get<IUser[]>(
            `${Environment.CONFIG_DELTA_API_URL}/v2/usuarios/`,
            {
              headers: {
                Authorization: `Bearer ${Environment.TOKEN_DELTA_API_URL}`,
              },
              params: {
                where: {
                  firebaseEmail: socialUser.email,
                  aplicativos: EAppType.Educador,
                },
                select: ["deltaId", "token", "nome", "escolaId"],
                limit: 1,
              },
            }
          );
          if (Array.isArray(usuarios) && usuarios.length > 0) {
            const usuario = usuarios[0];
            if (!usuario.token || !usuario.escolaId) {
              throw new Error("Token de acessoou escola não encontrada");
            }

            const codigoZeroEsqueda = parseInt(usuario.escolaId, 10)
              .toString()
              .padStart(4, "0");

            const { data } = await axios.post<IUser>(
              `${Environment.AUTH_API_URL}/${codigoZeroEsqueda}/auth/${EAppType.Educador}/refresh`,
              null,
              {
                headers: { Authorization: `Bearer ${usuario.token}` },
              }
            );

            await handleEntrar(data);
          }
        }
      } catch (error) {
        snack.error(getError(error));
      } finally {
        setSearching(false);
      }
    })();
  }, [handleEntrar, socialUser]);

  useEffect(() => {
    (async () => {
      if (codigoCliente || pathCliente) {
        try {
          setCarregandoInsituicoes(true);
          const where = codigoCliente
            ? { codigo: codigoCliente }
            : { path: pathCliente };
          const { data } = await axios.get<IInstituicao[] | undefined>(
            `${Environment.CONFIG_DELTA_API_URL}/v2/clientes`,
            {
              params: { where },
              headers: {
                Authorization: `Bearer ${Environment.TOKEN_DELTA_API_URL}`,
              },
            }
          );
          if (Array.isArray(data) && data.length > 0) {
            setInstituicao(data[0]);
            setInstituicaoText(data[0].apelido);
          }
        } catch (error) {
          snack.error(getError(error));
        } finally {
          setCarregandoInsituicoes(false);
        }
      }
    })();
  }, [codigoCliente, pathCliente, setValue]);

  useEffect(() => {
    if (instituicaoText && instituicaoText.length > 2) {
      (async () => {
        try {
          setCarregandoInsituicoes(true);
          const { data } = await axios.get<IInstituicao[] | undefined>(
            `${Environment.CONFIG_DELTA_API_URL}/clientes/pesquisa/${instituicaoText}`,
            {
              headers: {
                Authorization: `Bearer ${Environment.TOKEN_DELTA_API_URL}`,
              },
            }
          );
          if (data) setInstituicoes(data);
        } catch (error) {
          snack.error(getError(error));
        } finally {
          setCarregandoInsituicoes(false);
        }
      })();
    }
  }, [instituicaoText]);

  useEffect(() => {
    (async () => {
      try {
        if (
          socialUser &&
          socialUser.email != null &&
          instituicao &&
          !searching &&
          !usuarioEmail &&
          !loginManual &&
          !signing
        ) {
          setCarregandoUsuarioEmail(true);
          const codigoZeroEsqueda = instituicao.codigo
            .toString()
            .padStart(4, "0");
          const { data: service } = await axios.post<
            | {
                serviceToken: string;
              }
            | undefined
          >(
            `${Environment.AUTH_API_URL}/${codigoZeroEsqueda}/auth/${EAppType.Educador}/service-token`,
            {
              service: ETypeService.BuscaUsuarioParaAutenticacao,
            },
            {
              headers: {
                Authorization: `Bearer ${Environment.TOKEN_DELTA_API_URL}`,
              },
            }
          );
          if (!service || !service.serviceToken) {
            throw new Error("Erro ao obter o código do serviço");
          }

          const { data: user } = await axios.post<IUser | undefined>(
            `${Environment.AUTH_API_URL}/${codigoZeroEsqueda}/auth/${EAppType.Educador}/email-auth`,
            {
              token: service.serviceToken,
              email: socialUser.email,
            }
          );

          if (user) setUsuarioEmail(user);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (!error.response || error.response.status != 404) {
            snack.error(getError(error));
          }
        } else {
          snack.error(getError(error));
        }
      } finally {
        setCarregandoUsuarioEmail(false);
      }
    })();
  }, [instituicao, searching, socialUser, usuarioEmail, loginManual, signing]);

  useEffect(() => {
    if (redir && paramInstituicao && token && app) {
      axios
        .post<IUser>(
          `${Environment.AUTH_API_URL}/${paramInstituicao}/auth/${app}/refresh`,
          {
            token,
          }
        )
        .then((response) => {
          handleEntrar(response.data);
        })
        .catch((error) => {
          snack.error(getError(error));
        });
    }
  }, [redir, paramInstituicao, token, app, handleEntrar]);

  return (
    <Box
      sx={{
        background: "linear-gradient(rgb(151 211 170) 40%, rgb(185 255 202))",
        minHeight: "100%",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{ pt: ({ spacing }) => spacing(8) }}
      >
        <EsqueceuSenhaDialog>
          <>
            {instituicao && (
              <EsqueceuSenhaComponent
                onClose={handleHideDialog}
                instituicao={instituicao}
              />
            )}
          </>
        </EsqueceuSenhaDialog>
        <Paper sx={styles.paper}>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} textAlign="center">
                <Image
                  src={logoLogin}
                  alt="Logo Delta"
                  sx={{
                    maxWidth: 140,
                    maxHeight: 120,
                    position: "relative",
                    m: 0,
                    mt: "-76px",
                  }}
                />
                <Typography variant="h6">Educador</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ textAlign: "center" }}>
                  Entre com sua rede social para facilitar os próximos acessos
                </Typography>
              </Grid>
              {searching && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              {!socialUser && (
                <Grid item xs={12}>
                  <GoogleButton fullWidth variant="outlined" />
                </Grid>
              )}
              {!socialUser && (
                <Grid item xs={12}>
                  <AppleButton fullWidth variant="outlined" />
                </Grid>
              )}
              {socialUser && (
                <Grid item xs={12}>
                  {socialUser && (
                    <SocialUserComponent
                      signOut={async () => {
                        await signOut();
                        setInstituicao(null);
                        setUsuarioEmail(undefined);
                      }}
                    />
                  )}
                </Grid>
              )}
              {!searching && socialUser && !instituicao && !usuarioEmail && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    Este parece ser seu primeiro acesso. Escolha uma instituição
                    para continuar
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider>
                  <Typography variant="body1">ou</Typography>
                </Divider>
              </Grid>
              {!instituicao && (
                <Grid item xs={12}>
                  <Autocomplete
                    noOptionsText="Digite para começar a procurar"
                    loadingText="Carregando os instituições..."
                    closeText="Fechar"
                    clearText="Limpar"
                    openText="Abrir"
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Instituição"
                        helperText="Procure por nome ou cidade"
                      />
                    )}
                    loading={carregandoInsituicoes}
                    options={instituicoes}
                    getOptionLabel={(option: IInstituicao) => option.nome}
                    onInputChange={debounce((_, value, reason) => {
                      if (reason === "input") setInstituicaoText(value);
                      else setInstituicaoText(null);
                    }, 500)}
                    onChange={(_, v) => setInstituicao(v)}
                    renderOption={(props, option: IInstituicao) => (
                      <ListItem {...props}>
                        <ListItemAvatar>
                          <SchoolIcon />
                        </ListItemAvatar>
                        <ListItemText
                          primary={option.nome}
                          secondary={option.endereco}
                        />
                      </ListItem>
                    )}
                  />
                </Grid>
              )}
              {instituicao && instituicao.webserver && (
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardHeader
                      sx={{ p: 1 }}
                      title={instituicao.apelido}
                      titleTypographyProps={{
                        variant: "subtitle1",
                      }}
                      action={
                        codigoCliente || pathCliente ? undefined : (
                          <Tooltip title="Escolher outra instituição">
                            <IconButton
                              onClick={() => {
                                setInstituicao(null);
                                setLoginManual(false);
                                if (usuarioEmail) setUsuarioEmail(undefined);
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      }
                    />
                    <CardMedia
                      sx={{ height: 65, backgroundSize: "contain" }}
                      image={`${instituicao.webserver}/imagens/logo.png`}
                      title={instituicao.nome}
                    />
                    <CardContent
                      sx={({ spacing }) => ({ p: `${spacing(1)} !important` })}
                    >
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        textAlign="center"
                      >
                        {`${instituicao.endereco} - ${instituicao.cidade} - ${instituicao.estado}`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {!searching &&
                socialUser &&
                instituicao &&
                !usuarioEmail &&
                !carregandoUsuarioEmail &&
                !loginManual && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      Nenhum usuário encontrado na instituição selecionada com
                      seu e-mail. Será necessário fazer o login.
                    </Alert>
                  </Grid>
                )}
              {loginManual && socialUser && (
                <Grid item xs={12}>
                  <Alert severity="info">
                    Ao fazer login manual iremos conectar seus usuários para
                    facilitar o proximo acesso
                  </Alert>
                </Grid>
              )}
              {carregandoUsuarioEmail && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}
              {usuarioEmail && (
                <Grid item xs={12}>
                  <Alert severity="info">
                    Encontramos este usuário na instituição selecionada
                  </Alert>
                </Grid>
              )}
              {usuarioEmail && (
                <Grid item xs={12}>
                  <List>
                    <ListItem dense>
                      <ListItemAvatar>
                        <Avatar alt={usuarioEmail.nome} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={usuarioEmail.nome.rUpper()}
                        secondary={usuarioEmail.email}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title="Não sou eu" aria-label="remove-tooltip">
                          <IconButton
                            onClick={() => {
                              setUsuarioEmail(undefined);
                              setLoginManual(true);
                            }}
                          >
                            <AccountCancelIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Grid>
              )}
              {usuarioEmail && socialUser && (
                <Grid item xs={12}>
                  <LoadingButton
                    loading={submiting}
                    variant="outlined"
                    fullWidth
                    onClick={() =>
                      handleConectar({ usuario: usuarioEmail, socialUser })
                    }
                  >
                    Sou eu, conectar usuários e entrar
                  </LoadingButton>
                </Grid>
              )}
              {!usuarioEmail && (
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    {...applyProps("usuario")}
                    disabled={!instituicao || !instituicao.webserver}
                  />
                </Grid>
              )}
              {!usuarioEmail && (
                <Grid item xs={12}>
                  <Password
                    {...applyProps("senha")}
                    disabled={!instituicao || !instituicao.webserver}
                  />
                </Grid>
              )}
              {!usuarioEmail && (
                <Grid item xs={8}>
                  <Button
                    disabled={!instituicao || !instituicao.webserver}
                    onClick={handleOpenDialog}
                  >
                    Esqueceu sua senha?
                  </Button>
                </Grid>
              )}
              {!usuarioEmail && (
                <Grid item xs={4} textAlign="right">
                  <LoadingButton
                    loading={submiting}
                    variant="outlined"
                    type="submit"
                  >
                    Entrar
                  </LoadingButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="caption" align="center" component="p">
                  Não está no seu computador? Use o modo visitante para fazer
                  login com privacidade.
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </Paper>
        <Box sx={{ textAlign: "center", mt: 1 }}>
          <Image src={logo} alt="Logo Delta" sx={{ maxWidth: 96, m: 0 }} />
        </Box>
      </Container>
    </Box>
  );
};
