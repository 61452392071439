import React, { FC, useEffect, useState } from "react";

import { Box, Breakpoint, Container, useTheme } from "@mui/material";

import { useAppDispatch, useAppSelector } from "store";

import { useMobile } from "hooks/useMobile";

import { LoadingWithMessage } from "components/LoadingWithMessage";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { Rightbar } from "./components/Rightbar";
import { ToolbarWrapper } from "./styles";
import { Environment } from "configs";
import { ProviderScroll } from "context/ScrollContext";
import { useSessionStorage } from "hooks/useSessionStorage";
import { FeedbackJob } from "@deltasge/marauders-map";
import { showSnack } from "components/GlobalSnackbar";
import { useSocket } from "context/SocketContext";
import {
  ButtonFeedback,
  NotificationFeedback,
  RightbarFeedback,
} from "./components/Feedback";
import { toggleRightbar } from "store/modules/layout";

interface Props {
  loading?: boolean;
  children: React.ReactNode;
  buttons?: React.ReactNode[];
  searchElement?: React.ReactNode;
  title: string;
  subtitle?: string;
  sidebar?: boolean;
  maxWidth?: Breakpoint | false;
  useMargin?: boolean;
  rightbarChildren?: React.ReactNode;
  rightbarIcon?: React.ReactNode;
  useScroll?: boolean;
}

function sxMainBox(useMargin: boolean) {
  return () => ({
    p: useMargin ? 1 : 0,
    height: "100%",
    position: "relative",
  });
}

export const Main: FC<Props> = ({
  children,
  loading,
  buttons,
  title,
  subtitle,
  searchElement,
  sidebar = true,
  maxWidth = false,
  rightbarChildren,
  rightbarIcon,
  useMargin = true,
  useScroll = true,
}) => {
  const [feedbacks, setFeedbacks] = useSessionStorage<FeedbackJob[]>(
    "feedbacks",
    []
  );
  const [animate, setAnimate] = useState(false);
  const isMd = useMobile("md");
  const socket = useSocket();

  const dispatch = useAppDispatch();

  const {
    layout: {
      sidebar: { isOpen: sidebarIsOpen },
      rightbar: { isOpen: rightbarIsOpen },
      feedback,
    },
    usuario: { usuario },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (rightbarIsOpen && !rightbarChildren) {
      dispatch(toggleRightbar(false));
    } else if (!rightbarIsOpen && rightbarChildren && !isMd) {
      dispatch(toggleRightbar(true));
    }
  }, []);

  useEffect(() => {
    if (socket && usuario) {
      socket.on(`fila:feedback:${usuario.deltaId}`, (data: FeedbackJob) => {
        const { tipo } = data;
        feedbacks.unshift(data);
        setFeedbacks(feedbacks);
        setAnimate(true);
        if (tipo == "error")
          showSnack(data.mensagem, { variant: "error", key: data.criacao });
        else if (tipo == "success")
          showSnack(<NotificationFeedback feedback={data} />, {
            key: data.criacao,
            persist: true,
          });
      });
    }
    return () => {
      if (socket && usuario) {
        socket.off(`fila:feedback:${usuario.deltaId}`);
      }
    };
  }, [socket, usuario]);

  const feedbackIsOpen =
    feedback && feedback.isOpen != undefined ? feedback.isOpen : false;

  window.document.title = `Delta Educador - ${title}`;

  const theme = useTheme();
  const { unit } = theme.breakpoints;

  let calcHeight = Number(theme.spacing(8).replace(`${unit}`, ""));
  if (!isNaN(Number(theme.mixins.toolbar.minHeight))) {
    calcHeight =
      Number(theme.mixins.toolbar.minHeight) +
      Number(theme.spacing(1.5).replace(`${unit}`, ""));
  }

  if (feedbacks.length > 0) {
    const btnFeedback = <ButtonFeedback key="btn-feedback" animate={animate} />;
    if (!buttons) buttons = [btnFeedback];
    else buttons.unshift(btnFeedback);
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "calc(100% - env(keyboard-inset-height, 0px))",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Header
          buttons={buttons || []}
          title={title}
          subtitle={subtitle}
          searchElement={searchElement}
          hasRightbar={!!rightbarChildren}
          rightbarIcon={rightbarIcon}
        />
        {sidebar && <Sidebar />}

        <Box
          component="main"
          height="100%"
          marginLeft={
            !sidebarIsOpen || isMd || !sidebar ? 0 : Environment.DRAWER_WIDTH
          }
          marginRight={
            (feedbackIsOpen || rightbarIsOpen) && !isMd
              ? Environment.DRAWER_WIDTH
              : 0
          }
        >
          <Container
            sx={{
              p: "0 !important",
              height: `calc(100% - ${calcHeight}px)`,
            }}
            maxWidth={maxWidth}
          >
            <ToolbarWrapper />
            {loading && (
              <Box mt={2}>
                <LoadingWithMessage message="Carregando..." />
              </Box>
            )}
            {!loading && useScroll && (
              <ProviderScroll>
                <Box sx={sxMainBox(useMargin)}>{children}</Box>
              </ProviderScroll>
            )}
            {!loading && !useScroll && (
              <Box sx={sxMainBox(useMargin)}>{children}</Box>
            )}
          </Container>
        </Box>
        {rightbarChildren && <Rightbar>{rightbarChildren}</Rightbar>}
        {feedbacks.length > 0 && (
          <RightbarFeedback feedbacks={feedbacks} setFeedbacks={setFeedbacks} />
        )}
      </Box>
    </Box>
  );
};
