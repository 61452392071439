import React, {
  createContext,
  FC,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Scrollbars, ScrollValues } from "rc-scrollbars";

interface IPosition {
  left: number;
  top: number;
}

interface IScrollPosition {
  prev: IPosition;
  curr: IPosition;
}

const initialPosition: IScrollPosition = {
  prev: { left: 0, top: 0 },
  curr: { left: 0, top: 0 },
};

export const ScrollContext = createContext<{
  isBottom: boolean;
  setHowMuchBelow: (howMuchBelow: number) => void;
  scrollTop: (top?: number) => void;
  scrolled: boolean;
  howMuchBelow?: number;
  scroll: RefObject<Scrollbars> | null;
  update(callback?: (values: ScrollValues) => void): void;
  moving: boolean;
}>({
  isBottom: false,
  scrolled: false,
  setHowMuchBelow: () => {},
  scrollTop: () => {},
  scroll: null,
  update: () => {},
  moving: false,
});

export const useScroll = ({ howMuchBelow }: { howMuchBelow?: number } = {}) => {
  const context = useContext(ScrollContext);
  if (howMuchBelow !== undefined && howMuchBelow != context.howMuchBelow) {
    context.setHowMuchBelow(howMuchBelow);
  }
  return context;
};

export const ProviderScroll: FC<
  PropsWithChildren<{
    style?: React.CSSProperties;
    howMuchBelow?: number;
  }>
> = ({ children, style, howMuchBelow: initHowMuchBelow = 0 }) => {
  const scroll = useRef<Scrollbars>(null);
  const [isBottom, setIsBottom] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [howMuchBelow, setHowMuchBelowState] = useState(initHowMuchBelow);
  const [moving, setMoving] = useState(false);
  const [position, setPosition] = useState<IScrollPosition>(initialPosition);

  const handleUpdate = useCallback(
    ({ scrollTop, scrollHeight, clientHeight }: ScrollValues) => {
      const inBottom =
        (scrollTop + howMuchBelow) / (scrollHeight - clientHeight);
      const isBottom = inBottom != Infinity && inBottom > 1;
      setScrolled(scrollTop > howMuchBelow);
      setIsBottom(isBottom);
    },
    [howMuchBelow]
  );

  const scrollTop = useCallback(
    (top = 0) => {
      if (scroll.current) {
        scroll.current.scrollTop(top);
      }
    },
    [scroll]
  );

  const setHowMuchBelow = useCallback(
    (value: number) => {
      if (value != howMuchBelow) setHowMuchBelowState(value);
    },
    [howMuchBelow]
  );

  useEffect(() => {
    const moving = position.curr.top > position.prev.top;
    if (moving !== scrolled) setMoving(moving);
  }, [scrolled, position.curr.top, position.prev.top]);

  return (
    <ScrollContext.Provider
      value={{
        isBottom,
        setHowMuchBelow,
        scrollTop,
        scrolled,
        howMuchBelow,
        scroll,
        update: (args) => scroll.current?.update(args),
        moving,
      }}
    >
      <Scrollbars
        style={style}
        onUpdate={handleUpdate}
        onScroll={(e) => {
          setPosition({
            prev: { ...position.curr },
            curr: {
              top: e.currentTarget.scrollTop,
              left: e.currentTarget.scrollLeft,
            },
          });
        }}
        ref={scroll}
      >
        {children}
      </Scrollbars>
    </ScrollContext.Provider>
  );
};
