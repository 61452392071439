import React, { FC, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  TrayArrowUp as UploadIcon,
  Information as InfoIcon,
  Delete as DeleteIcon,
} from "mdi-material-ui";

import { Input } from "assets/styleds";
import { Avatar } from "components/Avatar";
import { snack } from "components/GlobalSnackbar";
import { Password } from "components/Inputs/Password";
import { useApplyProps } from "hooks/useApplyProps";
import { Main } from "pages/_layouts/Main";
import { useAppDispatch, useAppSelector } from "store";
import { set as setUsuario } from "store/modules/auth/usuario";
import { getError, sendS3PhotoUser } from "utils";
import { useDialog } from "hooks/useDialog";
import { AvatarEditor } from "components/AvatarEditor";
import { api, apiConfig } from "configs";
import {
  EUserType,
  IUser,
  IUserProfile,
  schemaUserProfile,
  utils,
} from "@deltasge/marauders-map";

export const Profile: FC = () => {
  const [saving, setSaving] = useState(false);
  const [image, setImage] = useState<File | null>(null);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IUserProfile>({
    resolver: yupResolver(schemaUserProfile),
    defaultValues: {
      ...(usuario ? usuario : {}),
      displayName: usuario?.displayName ?? usuario?.nome ?? "",
      photoS3: usuario?.photoS3,
      photoUrl: usuario?.photoUrl,
    },
  });

  const {
    RenderDialog: AvatarEditorDialog,
    show: showAvatarEditor,
    hide: hideAvatarEditor,
  } = useDialog({
    title: "Criar avatar",
  });

  const applyProps = useApplyProps<IUserProfile>({ register, errors });

  const onSubmit = async (profile: IUserProfile) => {
    try {
      setSaving(true);
      const params: Partial<IUser> = {
        displayName: profile.displayName,
        photoUrl: profile.photoUrl || null,
        photoS3: profile.photoS3 || false,
      };
      if (
        profile.photoUrl &&
        utils.isBase64(profile.photoUrl, { allowMime: true })
      ) {
        const file = utils.base64ToFile(profile.photoUrl, "foto.jpg");
        profile.photoUrl = await sendS3PhotoUser(file);
        params.photoUrl = profile.photoUrl;
        profile.photoS3 = true;
        params.photoS3 = true;
      }
      if (
        profile.senha &&
        profile.confirmaSenha &&
        profile.senha.length > 0 &&
        profile.senha == profile.confirmaSenha
      ) {
        let url = `usuario/${usuario?.id}`;
        if (usuario?.tipoUsuario == EUserType.Professor) {
          url = `professor/${usuario?.id}`;
        }
        await api.put(url, {
          senha: profile.senha,
        });
      }

      const { data: user } = await apiConfig.put<IUser>(
        `usuarios/usuario/${usuario?.deltaId}`,
        params
      );

      dispatch(setUsuario({ ...user }));

      snack.success("Perfil salvo com sucesso!");
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setSaving(false);
    }
  };

  const handleEditImage = (image: string) => {
    hideAvatarEditor();
    setValue("photoUrl", image);
  };
  const handleDeleteImage = () => {
    setImage(null);
    setValue("photoUrl", null);
  };

  return (
    <Main title="Perfil">
      <AvatarEditorDialog>
        {image && <AvatarEditor image={image} onSave={handleEditImage} />}
      </AvatarEditorDialog>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={4}>
            <Card
              sx={{
                margin: "auto",
                position: "relative",
                width: theme.spacing(19),
                height: theme.spacing(22),
                padding: theme.spacing(2),
              }}
            >
              <Avatar
                key={watch("photoUrl")}
                sx={{
                  position: "absolute",
                  width: theme.spacing(15),
                  height: theme.spacing(15),
                }}
                src={watch("photoUrl")}
                displayName={watch("displayName")}
                photoS3={watch("photoS3")}
              />
              <Box
                component="label"
                htmlFor="icon-button-file"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: theme.spacing(5),
                }}
              >
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={async ({ target: { files } }) => {
                    if (!files || !files.item(0)) {
                      return;
                    }
                    setImage(files.item(0));
                    showAvatarEditor();
                  }}
                />
                <IconButton aria-label="upload-arquivo" component="span">
                  <UploadIcon />
                </IconButton>
              </Box>
              <IconButton
                aria-label="delete-arquivo"
                component="span"
                onClick={handleDeleteImage}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: theme.spacing(10),
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Nome" {...applyProps("displayName")} />
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Typography variant="subtitle2" sx={{ pt: 1, pl: 1, pr: 1 }}>
                Alterar a senha do Delta SGE
              </Typography>
              {usuario?.firebaseId && (
                <Typography
                  variant="caption"
                  sx={{ padding: 1 }}
                  color="primary"
                >
                  <InfoIcon
                    style={{ fontSize: theme.spacing(2), verticalAlign: "sub" }}
                  />{" "}
                  A alteração não muda a senha de sua rede social
                </Typography>
              )}
              <Divider />
              <Grid container spacing={2} p={2}>
                <Grid item xs={12} sm={6}>
                  <Password label="Senha" {...applyProps("senha")} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Password
                    label="Confirme a senha"
                    {...applyProps("confirmaSenha")}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <LoadingButton
              loading={saving}
              fullWidth
              type="submit"
              variant={theme.components?.MuiButton?.defaultProps?.variant}
              color={theme.components?.MuiButton?.defaultProps?.color}
            >
              Gravar
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Main>
  );
};
