import { snack } from "components/GlobalSnackbar";
import { apiConfig, apiPush } from "configs";
import { IChatGroup, IUser } from "@deltasge/marauders-map";
import { Main } from "pages/_layouts/Main";
import React, { FC, useEffect, useState } from "react";
import { DotsVertical as MenuIcon } from "mdi-material-ui";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { getError, loadS3File } from "utils";
import { ChatUsers } from "./components/ChatUsers";
import { LinearProgress } from "@mui/material";
import { ChatComponent } from "./components/ChatComponent";

export const Chat: FC = () => {
  const [loading, setLoading] = useState(false);
  const [chatGroup, setChatGroup] = useState<IChatGroup>();

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const {
    deltaId: deltaIdBase64,
    idChatGroup,
    idSetor,
  } = useParams<{
    deltaId?: string;
    idChatGroup?: string;
    idSetor?: string;
  }>();

  const getChannel = async ({
    deltaIdBase64,
    idChatGroup,
  }: {
    deltaIdBase64?: string;
    idChatGroup?: string;
  }) => {
    // eslint-disable-next-line no-console
    console.log("getChannel");
    try {
      setLoading(true);
      let chat: IChatGroup | undefined;
      if (deltaIdBase64 && idSetor) {
        const deltaId = atob(deltaIdBase64);
        const { data } = await apiPush.put<IChatGroup>("/api/v3/chat-group/", {
          idSetor,
          idEscola: usuario?.escolaId,
          searchForUser: deltaId,
          users: [usuario?.deltaId, deltaId],
        });
        if (data) chat = data;
      } else {
        const { data } = await apiPush.get<IChatGroup>(
          `/api/v2/chat-group/${idChatGroup}`
        );
        if (data) {
          chat = {
            ...data,
            chats:
              data.chats && data.chats.length > 0 ? data.chats?.reverse() : [],
          };
        }
      }
      if (chat) {
        const { data: chatUsers } = await apiConfig.get<IUser[]>(
          `/v2/usuarios`,
          {
            params: {
              where: {
                deltaId: { $in: chat.users },
              },
            },
          }
        );
        if (chatUsers && chatUsers.length > 0) {
          chat.chatUsers = await Promise.all(
            await chatUsers.map(async (u) => {
              if (u.photoS3 && u.photoUrl) {
                const { processed } = await loadS3File(u.photoUrl, true);
                u.photoUrl = processed;
              }
              return u;
            })
          );
        }
        setChatGroup(chat);
      }
    } catch (error) {
      snack.warning(getError(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (deltaIdBase64 || idChatGroup) {
      getChannel({ deltaIdBase64, idChatGroup });
    }
  }, []);

  return (
    <Main
      useMargin={false}
      title="Conversa"
      subtitle={
        chatGroup && chatGroup.chatUsers
          ? chatGroup.chatUsers
              .map((m) =>
                m.deltaId == usuario?.deltaId ? "Você" : m.displayName ?? m.nome
              )
              .join(", ")
          : undefined
      }
      rightbarChildren={
        chatGroup && chatGroup.chatUsers && <ChatUsers chatGroup={chatGroup} />
      }
      rightbarIcon={<MenuIcon />}
    >
      {loading && <LinearProgress />}
      {!loading && chatGroup && (
        <ChatComponent chatGroup={chatGroup} setChatGroup={setChatGroup} />
      )}
    </Main>
  );
};
