import {
  CardContent,
  CardHeader,
  Card as MUICard,
  CardProps,
} from "@mui/material";
import React, { FC, PropsWithChildren, ReactNode } from "react";

export const Card: FC<
  PropsWithChildren<{
    title: string;
    cardProps?: CardProps;
    action?: JSX.Element & ReactNode;
  }>
> = ({ title, children, cardProps, action }) => {
  return (
    <MUICard {...cardProps}>
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: "subtitle2",
          component: "p",
          noWrap: true,
          textOverflow: "ellipsis",
        }}
        sx={{
          p: 1,
          pb: 0,
          "& .MuiCardHeader-content": {
            display: "block",
            overflow: "hidden",
          },
        }}
        action={action}
      />
      <CardContent
        sx={({ spacing }) => ({
          p: `${spacing(1)} !important`,
          pt: 0,
          height: `calc(100% - ${spacing(4.25)})`,
        })}
      >
        {children}
      </CardContent>
    </MUICard>
  );
};
