import React, { FC, useCallback, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { EAppType, IInstituicao } from "@deltasge/marauders-map";
import axios from "axios";
import { Environment } from "configs";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
export const EsqueceuSenhaComponent: FC<{
  onClose: () => void;
  instituicao: IInstituicao;
}> = ({ onClose, instituicao }) => {
  const [text, setText] = useState("");
  const [sending, setSending] = useState(false);

  const handleSend = useCallback(async () => {
    try {
      setSending(true);

      const codigoZeroEsqueda = instituicao.codigo.toString().padStart(4, "0");
      await axios.post(
        `${Environment.AUTH_API_URL}/${codigoZeroEsqueda}/esqueceu-senha/${EAppType.Educador}`,
        {
          texto: text,
          app: EAppType.Educador,
        }
      );

      onClose();
      snack.success("Você irá receber um email para a alteração da senha.");
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setSending(false);
    }
  }, [instituicao.codigo, onClose, text]);

  return (
    <>
      <DialogContent dividers>
        <TextField
          label="E-mail ou CPF"
          value={text}
          onChange={({ target: { value } }) => setText(value)}
          helperText={
            <Typography variant="caption">
              Caso você <b>não</b> seja aluno ou responsável financeiro do
              aluno, <b>utilize apenas o e-mail para recuperar a senha.</b>
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <LoadingButton
          variant="outlined"
          onClick={handleSend}
          disabled={!text}
          loading={sending}
        >
          Solicitar recuperação
        </LoadingButton>
      </DialogActions>
    </>
  );
};
