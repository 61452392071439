import { put, takeLatest, call, all } from "redux-saga/effects";

import { api } from "configs";
import { history } from "configs/history";
import { snack } from "components/GlobalSnackbar";
import { persistor } from "store";
import { resetStore } from "store/modules/app";

import { ActionTypes } from "./types";
import { failure, success } from "./actions";

function* request() {
  try {
    yield call(persistor.purge);

    api.defaults.headers.common["Authorization"] = "";

    yield put(success());
    yield put(resetStore());

    if ("sessionStorage" in window) {
      window.sessionStorage.clear();
    }

    history.replace("/login");
  } catch (error) {
    snack.error((error as Error).toString());
    yield put(failure());
  }
}

export const sagas = all([takeLatest(ActionTypes.REQUEST, request)]);
