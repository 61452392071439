import "@deltasge/marauders-map/lib/utils/string.extensions";

import React, { FC } from "react";
import { EChatType, IChat, IUser } from "@deltasge/marauders-map";
import { formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

import { Box, Grid, Paper, Typography } from "@mui/material";
import {
  Check as CheckIcon,
  CheckAll as CheckAllIcon,
  Clock as ClockIcon,
} from "mdi-material-ui";

import { Avatar } from "components/Avatar";

export const ChatItem: FC<{
  chat: IChat;
  user?: IUser;
  isMe: boolean;
}> = ({ chat, user, isMe }) => {
  let alignSelf: string | undefined = !isMe ? "start" : "end";
  if (chat.chatType == EChatType.Info) alignSelf = undefined;

  return (
    <Grid item alignSelf={alignSelf} sx={{ mt: 1, mb: 1 }}>
      <Grid container>
        {!isMe && chat.chatType != EChatType.Info && (
          <Grid item>
            <Avatar
              src={user?.photoUrl ?? undefined}
              displayName={user?.displayName ?? undefined}
            />
          </Grid>
        )}
        {chat.chatType != EChatType.Info && (
          <Grid
            item
            component={Paper}
            sx={{
              mr: 1,
              ml: 1,
              flex: 1,
              p: 1,
              background: (theme) =>
                isMe ? theme.palette.primary.main : undefined,
              textAlign: isMe ? "right" : "left",
            }}
            variant="outlined"
            elevation={0}
          >
            {!isMe && (
              <Typography
                variant="subtitle2"
                sx={{
                  color: (user?.displayName ?? user?.nome)?.toColor(),
                  textShadow: "1px 1px 0px rgba(0,0,0,0.25)",
                }}
              >
                {user?.displayName ?? user?.nome}
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: isMe
                  ? theme.palette.getContrastText(theme.palette.primary.main)
                  : "inherit",
              })}
            >
              {chat.context}
            </Typography>
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: isMe
                  ? theme.palette.getContrastText(theme.palette.primary.main)
                  : "inherit",
              })}
            >
              {formatDistance(
                parseISO(chat.createdAt?.toString() ?? ""),
                Date.now(),
                {
                  addSuffix: true,
                  locale: ptBR,
                }
              )}
              {isMe && (
                <Box component="span" sx={{ verticalAlign: "middle", pl: 0.5 }}>
                  {chat.delivered.length == 0 && (
                    <ClockIcon fontSize="inherit" />
                  )}
                  {chat.delivered.length == 1 && (
                    <CheckIcon fontSize="inherit" />
                  )}
                  {chat.delivered.length > 1 && (
                    <CheckAllIcon fontSize="inherit" />
                  )}
                </Box>
              )}
            </Typography>
          </Grid>
        )}
        {isMe && chat.chatType != EChatType.Info && (
          <Grid item>
            <Avatar
              src={user?.photoUrl ?? undefined}
              displayName={user?.displayName ?? undefined}
            />
          </Grid>
        )}
        {chat.chatType == EChatType.Info && (
          <Grid
            item
            component={Paper}
            variant="outlined"
            elevation={0}
            sx={{
              mr: 1,
              ml: 1,
              flex: 1,
              textAlign: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={(theme) => ({
                color: isMe ? theme.palette.primary.main : "inherit",
              })}
            >
              {chat.context}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
