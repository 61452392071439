import React, { FC, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { ChevronDown as ExpandMoreIcon } from "mdi-material-ui";
import { IFiltroMateria } from "@deltasge/marauders-map";

interface Props {
  selecionado?: IFiltroMateria;
  materias?: IFiltroMateria[];
  onClick: (id?: number) => void;
  render: "accordion" | "autocomplete";
  mostrarTodos: boolean;
}

export const FilterMateria: FC<Props> = ({
  selecionado,
  onClick,
  materias,
  render,
  mostrarTodos,
}) => {
  const [panel, setPanel] = useState(false);

  const handleClick = (id?: number) => {
    onClick(id);
    if (render == "accordion") setPanel(false);
  };

  if (render == "accordion") {
    return (
      <Accordion
        elevation={0}
        expanded={panel}
        onChange={() => setPanel(!panel)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container>
            <Grid item xs={12} component={Typography} variant="subtitle2">
              Matéria
            </Grid>
            <Grid item xs={12} component={Typography} variant="caption">
              {!selecionado && mostrarTodos
                ? "TODAS AS MATÉRIAS"
                : !selecionado && !mostrarTodos
                ? "SELECIONE UMA MATÉRIA"
                : selecionado?.nome?.rUpper()}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {mostrarTodos && (
              <ListItemButton onClick={() => handleClick()}>
                <ListItemIcon>
                  <Radio checked={undefined == selecionado} />
                </ListItemIcon>
                <ListItemText primary="TODAS AS MATÉRIAS" />
              </ListItemButton>
            )}

            {materias?.map((materia) => (
              <ListItemButton
                key={`filter-materia-${materia.id}`}
                onClick={() => handleClick(materia.id)}
              >
                <ListItemIcon>
                  <Radio checked={materia.id == selecionado?.id} />
                </ListItemIcon>
                <ListItemText
                  primary={materia.nome?.rUpper()}
                  primaryTypographyProps={{
                    noWrap: true,
                  }}
                />
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Autocomplete
      sx={{ m: 1 }}
      disablePortal
      id="filter-materia"
      options={materias ?? []}
      value={selecionado}
      renderInput={(params) => <TextField {...params} label="Matéria" />}
      getOptionLabel={(option) => option.nome?.rUpper() ?? ""}
      onChange={(event, value) => handleClick(value?.id)}
      noOptionsText="Selecione uma série"
      clearText="Todos as matérias"
      openText="Selecione uma matéria"
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText
            key={option.id}
            primary={option.nome?.rUpper()}
            primaryTypographyProps={{
              noWrap: true,
            }}
          />
        </ListItem>
      )}
    />
  );
};
