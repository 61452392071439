import "react-perfect-scrollbar/dist/css/styles.css";
import React from "react";
import ReactDOM from "react-dom";

import Button from "@mui/material/Button";
import SyncIcon from "@mui/icons-material/Sync";

import { showSnack } from "components/GlobalSnackbar";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { Environment } from "configs";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://2525029526bf3bca14c3c7e725782753@o4509055558483968.ingest.us.sentry.io/4509055789957120",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onUpdate: (registration: ServiceWorkerRegistration) => {
    setTimeout(() => {
      showSnack("Uma nova versão esta disponível", {
        variant: "info",
        action: () => (
          <Button
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<SyncIcon />}
            onClick={() => {
              registration.waiting?.postMessage({ type: "SKIP_WAITING" });
              window.location.reload();
            }}
          >
            Atualizar
          </Button>
        ),
      });
    }, 1500);
  },
  onSuccess: () => {
    showSnack("Uma nova versão foi instalada", {
      variant: "info",
    });
  },
});

if (Environment.IS_DEV_MODE) {
  // eslint-disable-next-line no-console
  reportWebVitals(console.log);
}
