import React, { FC, useState } from "react";

import {
  Checkbox,
  IconButton,
  ListItem as ListItemMUI,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  DotsVertical as MenuIcon,
  FolderStar as OcorrenciaIcon,
  Forum as ConversaIcon,
} from "mdi-material-ui";
import { history } from "configs";

import { useAppSelector } from "store";
import {
  EUserType,
  IAlunosChamada,
  IOcorrenciaPayload,
  utils,
} from "@deltasge/marauders-map";
import { snack } from "components/GlobalSnackbar";
import { useDialog } from "hooks/useDialog";
import { Avatar } from "components/Avatar";
import { CadastroOcorrencia } from "pages/Ocorrencias/Cadastro/CadastroOcorrencia";

export interface IChamada extends IAlunosChamada {
  selected: boolean;
}

export const ListItem: FC<{
  aluno: IAlunosChamada;
  handlePresenca: ({
    presenca,
    idMatricula,
  }: {
    idMatricula: number;
    presenca: boolean;
  }) => Promise<void>;
  divider: boolean;
  idTurma: number;
}> = ({ aluno, divider, handlePresenca, idTurma }) => {
  const {
    usuario: { usuario },
    configuracoes: {
      configOnline: { configuracoes },
      etapas,
    },
    treeView: { cursos },
  } = useAppSelector((state) => state);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [ocorrencia, setOcorrencia] = useState<IOcorrenciaPayload>();

  const {
    RenderDialog: SetorDialog,
    hide: hideSetorDialog,
    show: showSetorDialog,
  } = useDialog({
    title: "Selecione o setor",
    options: {
      maxWidth: "xs",
    },
  });

  const {
    RenderDialog: OcorrenciaDialog,
    hide: hideOcorrenciaDialog,
    show: showOcorrenciaDialog,
  } = useDialog({
    title: "Criar ocorrência",
    options: {
      scroll: "paper",
    },
  });

  const handleOcorrencia = (aluno: IChamada) => {
    setAnchorEl(null);
    const etapa = utils.getEtapaPorData({
      etapas,
      data: new Date(),
      idTurma,
      cursos,
    });
    if (!etapa) {
      snack.warning(
        "Não foi possível encontrar a etapa do curso desta turma para a data atual"
      );
      return;
    }

    setOcorrencia({
      idCategoriaOcorrencia: null,
      anoLetivo: configuracoes.ano_site.toString(),
      restrita: false,
      historico: "",
      bimestre: etapa?.etapa,
      data: new Date(),
      alunos: [{ id: aluno.alunoUid, nome: aluno.alunoNome }],
      idTiposOcorrencia: [],
      idMateria: null,
      idTurma,
      idProfessor:
        usuario?.tipoUsuario == EUserType.Professor && usuario.id
          ? usuario.id
          : null,
      idUsuario:
        usuario?.tipoUsuario == EUserType.UsuarioSistema
          ? usuario?.id ?? null
          : null,
    });
    showOcorrenciaDialog();
  };

  const idSetores: { key: string; name: string }[] = [];
  if (usuario?.secretaria) {
    idSetores.push({ key: "secretaria", name: "Secretaria" });
  }
  if (usuario?.coordenacao) {
    idSetores.push({ key: "coordenacao", name: "Coordenação" });
  }
  if (usuario?.tesouraria) {
    idSetores.push({ key: "tesouraria", name: "Tesouraria" });
  }
  if (usuario?.diretoria) {
    idSetores.push({ key: "diretoria", name: "Diretoria" });
  }

  const deltaId = aluno?.deltaId ? btoa(aluno?.deltaId) : undefined;

  return (
    <>
      <OcorrenciaDialog>
        {ocorrencia && (
          <CadastroOcorrencia
            handleClose={hideOcorrenciaDialog}
            item={ocorrencia}
            goToList
          />
        )}
      </OcorrenciaDialog>
      <SetorDialog>
        {idSetores.map((m) => (
          <ListItemMUI key={m.key} disablePadding>
            <ListItemButton
              onClick={() => {
                hideSetorDialog();
                history.push(`/chat/setor/${m.key}/${deltaId}`);
              }}
            >
              <ListItemText primary={m.name} />
            </ListItemButton>
          </ListItemMUI>
        ))}
      </SetorDialog>
      <ListItemMUI
        key={"aluno_" + aluno.nChamada}
        divider={divider}
        disablePadding
        secondaryAction={
          <>
            <Tooltip title="Menu">
              <IconButton
                edge="end"
                aria-label="more-vert"
                onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleOcorrencia(aluno as IChamada)}>
                <ListItemIcon>
                  <OcorrenciaIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Criar ocorrência</ListItemText>
              </MenuItem>
              {deltaId && (
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    if (deltaId) {
                      if (usuario?.tipoUsuario == EUserType.Professor) {
                        history.push(`/chat/setor/professor/${deltaId}`);
                      } else if (idSetores.length > 1) {
                        showSetorDialog();
                      } else if (idSetores.length == 1) {
                        history.push(
                          `/chat/setor/${idSetores[0].key}/${deltaId}`
                        );
                      } else {
                        snack.warning(
                          "Seu usuário não tem nenhum perfil configurado"
                        );
                      }
                    }
                  }}
                >
                  <ListItemIcon>
                    <ConversaIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Iniciar uma conversa</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </>
        }
      >
        <ListItemButton
          role={undefined}
          onClick={async () => {
            await handlePresenca({
              presenca: aluno.presenca != true,
              idMatricula: aluno.idMatricula,
            });
          }}
          dense
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              indeterminate={aluno.presenca == undefined}
              checked={aluno.presenca}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": "aluno_" + aluno.nChamada }}
            />
          </ListItemIcon>
          <ListItemAvatar>
            <Avatar
              key={"avatar_" + aluno.alunoUid}
              displayName={aluno.alunoNome}
              src={aluno.photoUrl}
            />
          </ListItemAvatar>
          <ListItemText
            id={"aluno_" + aluno.nChamada}
            primary={`${aluno.nChamada} - ${aluno.alunoNome.rUpper()}`}
          />
        </ListItemButton>
      </ListItemMUI>
    </>
  );
};
