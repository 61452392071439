import React, { FC } from "react";

import {
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Typography,
  Box,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "store";
import { toggleSidebar } from "store/modules/layout";
import { useAuthentication } from "context/AuthenticationProvider";
import { useMobile } from "hooks/useMobile";
import { history } from "configs";
import { LogoutRounded } from "@mui/icons-material";
import { useConfirmDialog } from "hooks/useDialogConfirm";

export const Profile: FC = () => {
  const { escola } = useAppSelector((state) => state);

  const isMobile = useMobile();
  const dispatch = useAppDispatch();

  const { AuthenticatedUserComponent, signOut } = useAuthentication();

  const { show: showSair, RenderDialog: DialogSair } = useConfirmDialog({
    defaults: {
      title: "Sair da aplicativo",
      content: "Tem certeza que deseja sair do aplicativo?",
    },
    onConfirmed: () =>
      signOut({
        after: () => window.location.reload(),
      }),
  });

  return (
    <List>
      <DialogSair />
      <ListItem>
        <ListItemText primary={escola.nome} />
      </ListItem>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        alignContent="center"
      >
        <AuthenticatedUserComponent
          onClick={() => {
            if (isMobile) {
              dispatch(toggleSidebar());
            }
            history.push("/profile/");
          }}
        />
        <Divider orientation="vertical" flexItem />
        <IconButton
          onClick={showSair}
          size="large"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <LogoutRounded />
          <Typography sx={{ fontSize: "small" }}>SAIR</Typography>
        </IconButton>
      </Box>
      <Divider />
    </List>
  );
};
