/* eslint-disable no-console */
import React, { Dispatch, FC, SetStateAction } from "react";
import { IconButton, LinearProgress, TextField } from "@mui/material";
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RigthIcon,
  OpenInNew as OpenIcon,
} from "mdi-material-ui";
import { Card } from "./Card";
import {
  IFiltroMateria,
  IMensbol,
  INotaDis,
  ITurmaDis,
} from "@deltasge/marauders-map";
import { useDialog } from "hooks/useDialog";
import { DialogTurmaDescritiva } from "./DialogTurmaDescritiva";

export const TurmaDescritiva: FC<{
  materia?: IFiltroMateria;
  materias: IFiltroMateria[];
  handleFilterMateria: (idMateria?: number) => void;
  turmaDis?: ITurmaDis;
  loading?: boolean;
  setDescritiva: Dispatch<
    SetStateAction<
      | {
          mensBol?: IMensbol;
          turmaDis?: ITurmaDis;
          notaDis?: INotaDis;
        }
      | undefined
    >
  >;
  errors:
    | {
        mensBol?: string;
        turmaDis?: string;
        notaDis?: string;
      }
    | undefined;
}> = ({
  materia,
  materias,
  handleFilterMateria,
  loading = false,
  setDescritiva,
  turmaDis,
  errors,
}) => {
  const index = materia
    ? materias.findIndex((f) => f.id == materia.id)
    : undefined;

  const title = materia
    ? `${materia.codigo}-${materia.nome?.rUpper()}`
    : "Objetivo da Matéria";

  const { RenderDialog, show, hide } = useDialog({
    title,
    options: {
      modal: true,
      maxWidth: "md",
      autoWidth: true,
    },
  });

  return (
    <Card
      cardProps={{ variant: "outlined", sx: { height: "21%", mb: 1 } }}
      title={title}
      action={
        <>
          <IconButton size="small" onClick={show}>
            <OpenIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={index == 0 || index == undefined}
            onClick={
              index != undefined
                ? () => {
                    handleFilterMateria(materias.at(index - 1)?.id);
                  }
                : undefined
            }
          >
            <LeftIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={index == materias.length - 1 || index == undefined}
            onClick={
              index != undefined
                ? () => {
                    handleFilterMateria(materias.at(index + 1)?.id);
                  }
                : undefined
            }
          >
            <RigthIcon />
          </IconButton>
        </>
      }
    >
      <RenderDialog>
        {turmaDis && (
          <DialogTurmaDescritiva
            turmaDis={turmaDis}
            onClose={(descricao) => {
              setDescritiva((p) => ({
                ...p,
                turmaDis: { ...turmaDis, descricao },
              }));
              hide();
            }}
          />
        )}
      </RenderDialog>
      {loading && <LinearProgress />}
      {!loading && (
        <TextField
          label="Objetivo da matéria"
          multiline
          error={!!errors?.turmaDis}
          helperText={errors?.turmaDis}
          disabled={turmaDis == undefined}
          size="small"
          inputProps={{
            style: { height: "100%", overflow: "auto", fontSize: "14px" },
          }}
          value={turmaDis?.descricao || ""}
          sx={{
            width: "100%",
            maxHeight: "100%",
            height: "100%",
            "& .MuiInputBase-root": {
              height: "100%",
            },
          }}
          onChange={
            turmaDis
              ? ({ target: { value } }) =>
                  setDescritiva((p) => ({
                    ...p,
                    turmaDis: { ...turmaDis, descricao: value },
                  }))
              : undefined
          }
        />
      )}
    </Card>
  );
};
