import React, { FC, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  EUserType,
  IChat,
  IChatEmit,
  IChatGroup,
  IMensagemAtribuicaoDestinatario,
} from "@deltasge/marauders-map";
import Axios, { CancelTokenSource } from "axios";
import { formatDistance, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import {
  IMensagem,
  IMensagemResposta,
  EChatType,
  IUser,
} from "@deltasge/marauders-map";

import {
  Alert,
  Badge,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close as CloseIcon, Send as SendIcon } from "mdi-material-ui";
import { Scrollbars } from "rc-scrollbars";

import { api, apiConfig, apiPush, Environment, history } from "configs";
import { snack } from "components/GlobalSnackbar";
import { Anexo } from "components/Anexo";
import { Avatar } from "components/Avatar";
import { ChatItem } from "components/ChatItem";
import { useSocket } from "context/SocketContext";
import { useMobile } from "hooks/useMobile";
import { useConfirmDialog } from "hooks/useDialogConfirm";
import { useAppSelector } from "store";
import { getError, isImage, loadS3File } from "utils";

interface Params {
  select: string[];
  idMensagem?: number;
  idAluno?: number;
  idResponsavel?: number;
  idLoginExtra?: number;
  idProfessor?: number;
}

interface Anexo {
  url: string;
  title: string;
  isLink: boolean;
  hasError: boolean;
  isImage: boolean;
}

export const DetalhesDestinatario: FC<{
  destinatario: IMensagemAtribuicaoDestinatario;
  mensagem: IMensagem;
  source: CancelTokenSource;
  showClose: boolean;
}> = ({ destinatario, mensagem, source, showClose }) => {
  const { idMensagem, tab } = useParams<{
    idMensagem: string;
    tab: string;
  }>();

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [loading, setLoading] = useState(true);
  const [loadingChat, setLoadingChat] = useState(true);
  const [anexos, setAnexos] = useState<Anexo[]>([]);
  const [resposta, setResposta] = useState<IMensagemResposta>();
  const [message, setMessage] = useState("");
  const [chatGroup, setChatGroup] = useState<IChatGroup>();

  const scroll = useRef<Scrollbars>(null);

  const isSm = useMobile("sm");

  const socket = useSocket();

  const { show: showDevolverDialog, RenderDialog: DevolverDialog } =
    useConfirmDialog<number>({
      defaults: {
        title: "Devolver entrega?",
        content: "Tem certeza que deseja devolver?",
      },
      onConfirmed: async (payload, hide) => {
        try {
          await api.put<IMensagemResposta>(
            `educador/mensagem-resposta/${payload}`,
            {
              idMensagem: mensagem.id ?? 0,
              devolvido: true,
            }
          );
          await getResposta();
        } catch (error) {
          snack.warning((error as Error).toString());
        } finally {
          hide();
        }
      },
    });

  const getResposta = async () => {
    try {
      if (mensagem.respostaComAnexo) {
        const params: Params = {
          select: [
            "id",
            "updatedAt",
            "devolvido",
            "anexos.arquivo",
            "links",
            "idAluno",
            "idResponsavel",
            "idProfessor",
            "idLoginExtra",
          ],
          idMensagem: mensagem.id,
        };
        if (destinatario.tipoUsuario == EUserType.Aluno) {
          params.idAluno = destinatario.id || undefined;
        } else if (destinatario.tipoUsuario == EUserType.Responsavel) {
          params.idResponsavel = destinatario.id || undefined;
        } else if (destinatario.tipoUsuario == EUserType.LoginExtra) {
          params.idLoginExtra = destinatario.id || undefined;
        } else {
          params.idProfessor = destinatario.id || undefined;
        }
        const { data } = await api.get<[IMensagemResposta[]]>(
          `educador/mensagem-resposta/`,
          {
            params,
            cancelToken: source.token,
          }
        );
        if (data && data.length > 0) {
          const respostas = data[0];
          const anexos: Anexo[] = [];
          setResposta(respostas[0]);
          for await (const resposta of respostas) {
            if (resposta.anexos) {
              for await (const anexo of resposta.anexos) {
                const { processed, error } = await loadS3File(
                  anexo.arquivo?.caminhoArquivo ?? ""
                );
                anexos.push({
                  title: anexo.arquivo?.nomeArquivo ?? "",
                  isLink: false,
                  url: processed ?? "",
                  hasError: error != undefined,
                  isImage: isImage(anexo.arquivo?.nomeArquivo ?? ""),
                });
              }
            }
            if (resposta.links) {
              for (const link of resposta.links) {
                anexos.push({
                  isLink: true,
                  title: link.url,
                  hasError: false,
                  url: link.url,
                  isImage: false,
                });
              }
            }
          }
          setAnexos(anexos);
        }
      }
    } catch (error) {
      if (!Axios.isCancel(error)) {
        snack.warning(getError(error));
      }
    } finally {
      setLoading(false);
    }
  };

  const getChannel = async () => {
    try {
      setLoadingChat(true);
      const { data } = await apiPush.put<IChatGroup>(`/api/v3/chat-group`, {
        idMensagem: mensagem.id,
        idEscola: usuario?.escolaId,
        searchForUser: destinatario.deltaId,
        users: [usuario?.deltaId, destinatario.deltaId],
      });
      if (data) {
        const { data: chatUsers } = await apiConfig.get<IUser[]>(
          "/v2/usuarios",
          {
            params: {
              where: {
                deltaId: { $in: data.users },
              },
            },
          }
        );
        if (chatUsers && chatUsers.length > 0) {
          data.chatUsers = await Promise.all(
            await chatUsers.map(async (u) => {
              if (u.photoS3 && u.photoUrl) {
                const { processed } = await loadS3File(u.photoUrl);
                if (processed) u.photoUrl = processed;
              }
              return u;
            })
          );
        }

        await chatWith(data._id);

        setChatGroup({
          ...data,
          chats:
            data.chats && data.chats.length > 0 ? data.chats?.reverse() : [],
        });
        if (socket && data._id) {
          if (
            data.chats &&
            !data.chats.find((f) =>
              f.delivered.map((m) => m.deltaId).includes(usuario?.deltaId ?? "")
            )
          ) {
            setDelivered({ chatGroup: data });
          }
          socket.on(data._id, (chat: IChat) => handleChat(chat, data));
        }
        if (scroll.current) {
          scroll.current.scrollToBottom();
        }
      }
    } catch (error) {
      if (!Axios.isCancel(error)) {
        snack.warning(getError(error));
      }
    } finally {
      setLoadingChat(false);
    }
  };

  const handleChat = (data: IChat, chatGroup: IChatGroup) => {
    if (
      data.fromId != usuario?.deltaId &&
      !data.delivered.find((f) => f.deltaId == usuario?.deltaId)
    ) {
      setDelivered({ id: data._id, chatGroup: chatGroup });
    }

    setChatGroup((prev) => {
      const chats: IChat[] = [];
      if (prev?.chats) chats.push(...prev.chats);

      const index = chats.findIndex((f) => f._id == data._id);
      if (index >= 0) chats[index] = data;
      else chats.unshift(data);

      return {
        ...chatGroup,
        chats,
      };
    });
    if (scroll.current) {
      scroll.current.scrollToBottom();
    }
  };

  useEffect(() => {
    getResposta();
    getChannel();
    return clearChatWith;
  }, [destinatario.id]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", clearChatWith);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", clearChatWith);
      socket?.off(chatGroup?._id);
    };
  }, []);

  const clearChatWith = () => {
    chatWith();
  };

  const chatWith = async (chatWith?: string) => {
    if (usuario && usuario.deltaId && usuario.escolaId && usuario.token) {
      const data: Record<string, string> = {
        deltaId: usuario?.deltaId,
        escolaId: usuario?.escolaId,
        token: usuario?.token,
      };
      if (chatWith) data.chatWith = chatWith;

      const params = new URLSearchParams(data).toString();

      navigator.sendBeacon(
        `${Environment.CONFIG_DELTA_API_URL}/usuario/chat-with?${params}`
      );
    }
  };

  const alertUser = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = "Deseja fechar esta conversa?");
  };

  const handleSendMsg = () => {
    try {
      if (message.trim() == "") return;
      const chat: IChat = {
        chatType: EChatType.Text,
        context: message,
        fromId: usuario?.deltaId ?? "",
        viewed: [],
        delivered: [],
      };
      const chatEmit: IChatEmit = {
        id: chatGroup?._id,
        chat,
      };
      socket?.emit("chat:msg:v3", chatEmit);

      setMessage("");
      if (scroll.current) {
        scroll.current.scrollToBottom();
      }
    } catch (err) {
      snack.warning(getError(err));
    }
  };

  const setDelivered = ({
    id,
    chatGroup,
  }: {
    id?: string;
    chatGroup: IChatGroup;
  }) => {
    socket?.emit("chat:setDelivered", {
      chatGroupId: chatGroup._id,
      deltaId: usuario?.deltaId,
      chatId: id,
    });
  };

  const sizeOfChat = mensagem.respostaComAnexo ? (isSm ? 29.5 : 28) : 9.75;

  return (
    <Grid
      container
      sx={(theme) => ({
        mt: theme.spacing(1),
        pr: theme.spacing(1),
        pl: showClose ? theme.spacing(1) : 0,
        height: "100%",
      })}
      direction="column"
      flexWrap="nowrap"
    >
      <DevolverDialog />
      <Grid item xs={12} sx={{ flex: "1 !important" }}>
        <Paper sx={{ p: 1, mb: 1 }} variant="outlined">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Avatar
                src={destinatario.photoUrl}
                displayName={destinatario.displayName ?? destinatario.nome}
                sx={{ mr: 1 }}
              />
            </Grid>
            <Grid item flex={1}>
              <Typography variant="subtitle1">
                {destinatario.displayName ? (
                  <Tooltip title={destinatario.nome}>
                    <span>
                      {destinatario.codigo
                        .concat(
                          destinatario.nrChamada
                            ? " - ".concat(destinatario.nrChamada).concat(" - ")
                            : " - "
                        )
                        .concat(destinatario.displayName)}
                    </span>
                  </Tooltip>
                ) : (
                  destinatario.codigo
                    .concat(
                      destinatario.nrChamada
                        ? " - ".concat(destinatario.nrChamada).concat(" - ")
                        : " - "
                    )
                    .concat(destinatario.nome)
                )}
              </Typography>
              {destinatario.detalhe && (
                <Typography variant="caption">
                  {destinatario.detalhe.codigo
                    .concat(
                      destinatario.detalhe.nrChamada
                        ? " - ".concat(destinatario.detalhe.nrChamada)
                        : ""
                    )
                    .concat(" - ")
                    .concat(destinatario.detalhe.nome)}
                </Typography>
              )}
            </Grid>
            <Grid item>
              {mensagem.respostaComAnexo &&
                resposta?.devolvido == undefined && <Chip label="Atribuido" />}
              {mensagem.respostaComAnexo && resposta?.devolvido == true && (
                <Tooltip
                  title={formatDistance(
                    parseISO((resposta.updatedAt ?? "").toString()),
                    Date.now(),
                    {
                      addSuffix: true,
                      locale: ptBR,
                    }
                  )}
                >
                  <Chip color="secondary" label="Devolvido" />
                </Tooltip>
              )}
              {mensagem.respostaComAnexo && resposta?.devolvido == false && (
                <Button
                  variant="text"
                  sx={{ mr: 1 }}
                  onClick={() => showDevolverDialog(resposta.id ?? 0)}
                >
                  Devolver
                </Button>
              )}
            </Grid>
            {showClose && (
              <Grid item>
                <Tooltip title="Fechar">
                  <IconButton
                    onClick={() =>
                      history.push(
                        `/publicacoes/detalhes/${idMensagem}/tab/${tab}`
                      )
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      {mensagem.respostaComAnexo && (
        <Grid
          item
          xs={12}
          component={Paper}
          variant="outlined"
          p={1}
          pb={4}
          sx={{ flex: "1 !important" }}
        >
          <Badge badgeContent={anexos.length} color="secondary">
            <Typography variant="h6">Anexos</Typography>
          </Badge>
          {loading && <LinearProgress />}
          {!loading && anexos.length == 0 && (
            <Alert severity="info">Nenhum anexos encontrado</Alert>
          )}
          <Scrollbars>
            <Stack direction="row" spacing={1}>
              {anexos.map((anexo, index) => (
                <Anexo
                  key={`anexo-resp-${index}`}
                  title={anexo.title}
                  url={anexo.url}
                  isLink={anexo.isLink}
                  sx={{
                    root: { mt: 0 },
                  }}
                />
              ))}
            </Stack>
          </Scrollbars>
        </Grid>
      )}
      {mensagem.permiteResposta && (
        <Grid
          item
          xs={12}
          component={Paper}
          variant="outlined"
          sx={{
            p: 1,
            mt: 1,
            height: (theme) => `calc(100% - ${theme.spacing(sizeOfChat)})`,
          }}
        >
          <Typography variant="h6">Comentários</Typography>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="stretch"
            sx={{ height: ({ spacing }) => `calc(100% - ${spacing(4)})` }}
          >
            <Grid
              item
              sx={{
                height: (theme) => `calc(100% - ${theme.spacing(6)})`,
                pb: 1,
              }}
            >
              <Scrollbars ref={scroll}>
                <Grid
                  container
                  direction="column-reverse"
                  flexWrap="nowrap"
                  justifyContent="flex-end"
                  sx={{ height: "100%", p: 1 }}
                >
                  {loadingChat && (
                    <Grid item>
                      <LinearProgress />
                    </Grid>
                  )}
                  {(chatGroup?.chats ?? []).map((m, i) => (
                    <ChatItem
                      key={`${i}_${m._id}`}
                      chat={m}
                      user={chatGroup?.chatUsers?.find(
                        (f) => f.deltaId == m.fromId
                      )}
                      isMe={usuario?.deltaId == m.fromId}
                    />
                  ))}
                </Grid>
              </Scrollbars>
            </Grid>
            <Grid item>
              <TextField
                value={message}
                onChange={({ target: { value } }) => setMessage(value)}
                onKeyPress={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                    handleSendMsg();
                  }
                }}
                disabled={!socket?.connected}
                label="Mensagem"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="send-message"
                        onClick={handleSendMsg}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
