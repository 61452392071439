import { fileTypeFromBlob } from "file-type";

export const urlToFile = async ({
  url,
  fileName,
}: {
  url: string;
  fileName?: string;
}): Promise<File> => {
  if (!fileName) {
    fileName = (url.match(/^\w+:(\/+([^/#?\s]+)){2,}(#|\?|$)/) || [])[2] || "";
  }

  return fetch(url)
    .then((res) => res.blob())
    .then(async (myBlob) => {
      const fileType = await fileTypeFromBlob(myBlob);
      return new File([myBlob], fileName as string, {
        type: fileType ? fileType.mime : myBlob.type,
      });
    });
};
