import React, { useCallback, useEffect } from "react";
import {
  IFiltroCurso,
  IFiltroSerie,
  IFiltroTurma,
  IPeriodoEtapa,
  utils,
} from "@deltasge/marauders-map";
import { Primitives, useQueryStorage } from "hooks/useQueryStorage";
import { useAppDispatch, useAppSelector } from "store";
import { request as getSistemaAvaliacao } from "store/modules/sistema-avaliacao";
import { FilterCurso } from "./FilterCurso";
import { FilterSerie } from "./FilterSerie";
import { FilterTurma } from "./FilterTurma";
import { FilterMateria } from "./FilterMateria";
import { FilterEtapa } from "./FilterEtapa";
import { FilterData } from "./FilterData";
import { parseISO } from "date-fns";
import { IFilterListas, IFilterSelecionado, IUseFilter } from "interfaces";

export interface IOptionsClick<
  Query extends { [K in keyof Query]?: Primitives } = {},
  Params extends { [K in keyof Params]?: string } = {}
> {
  navigate: (params: Query, path?: string) => void;
  params: Params;
  filter: Query;
}

export interface IOptions<
  Query extends { [K in keyof Query]?: Primitives } = {},
  Params extends { [K in keyof Params]?: string } = {}
> {
  onClick: (id?: number, options?: IOptionsClick<Query, Params>) => void;
  mostrarTodos?: boolean;
}

export interface IOptionsDate<
  Query extends { [K in keyof Query]?: Primitives } = {},
  Params extends { [K in keyof Params]?: string } = {}
> {
  onClick: (data?: Date, options?: IOptionsClick<Query, Params>) => void;
  minDate?: Date;
  maxDate?: Date;
  etapaMinMax?: boolean;
  allowsToClean?: boolean;
}

export interface IQuery<
  Query extends { [K in keyof Query]?: Primitives } = {},
  Params extends { [K in keyof Params]?: string } = {}
> {
  pathname: string;
  initialValue?: Query;
  render?: "accordion" | "autocomplete";
  descritivos?: boolean;
  options?: {
    curso?: IOptions<Query, Params>;
    serie?: IOptions<Query, Params>;
    turma?: IOptions<Query, Params>;
    materia?: IOptions<Query, Params>;
    etapa?: IOptions<Query, Params>;
    data?: IOptionsDate<Query, Params>;
    dataIni?: IOptionsDate<Query, Params>;
    dataFim?: IOptionsDate<Query, Params>;
  };
  queryKeys: (keyof Query)[];
}

export interface FilterUse<
  Query extends { [K in keyof Query]?: Primitives } = {},
  Params extends { [K in keyof Params]?: string } = {}
> {
  selecionados: IFilterSelecionado;
  listas: IFilterListas;
  Component: () => JSX.Element;
  CursoComponent: () => JSX.Element | undefined;
  SerieComponent: () => JSX.Element | undefined;
  TurmaComponent: () => JSX.Element | undefined;
  MateriaComponent: () => JSX.Element | undefined;
  EtapaComponent: () => JSX.Element | undefined;
  DataComponent: () => JSX.Element | undefined;
  DataIniComponent: () => JSX.Element | undefined;
  DataFimComponent: () => JSX.Element | undefined;
  navigate: (params: Query, path?: string) => void;
  filter: Query;
  params: Params;
}

export const useFilter = <
  Query extends { [K in keyof Query]?: Primitives } = {},
  Params extends { [K in keyof Params]?: string } = {}
>({
  descritivos = false,
  pathname,
  initialValue,
  render = "accordion",
  options = {},
  queryKeys,
}: IQuery<Query, Params>): FilterUse<Query, Params> => {
  const {
    usuario: { usuario },
    treeView: { cursos: todosOsCursos },
    configuracoes: {
      etapas: todasEtapas,
      configOnline: { configuracoes },
    },
    sistemaAvaliacao,
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const key = "filtros";

  const [filter, navi, params] = useQueryStorage<IUseFilter & Query, Params>({
    key,
    initialValue: initialValue != undefined ? initialValue : ({} as Query),
    keysOfCache: [
      "idCurso",
      "idSerie",
      "idTurma",
      "idMateria",
      "etapa",
      "data",
      "dataIni",
      "dataFim",
    ],
  });

  const cursos = utils.getCursosTreeView({
    cursos: todosOsCursos,
    somenteDescritivos: descritivos,
  });

  let curso: IFiltroCurso | undefined = undefined;
  if (filter.idCurso || !options.curso?.mostrarTodos) {
    curso = utils.getCursoTreeView({
      cursos,
      type: usuario?.tipoUsuario,
      idCurso: filter.idCurso?.toString() ?? null,
    });
  }

  const series = utils.getSeriesTreeView({
    series: curso?.series,
    somenteDescritivos: descritivos,
  });

  let serie: IFiltroSerie | undefined = undefined;
  if (filter.idSerie || !options.serie?.mostrarTodos) {
    serie = utils.getSerieTreeView({
      type: usuario?.tipoUsuario,
      cursos,
      idCurso: filter.idCurso?.toString() ?? null,
      idSerie: filter.idSerie?.toString() ?? null,
    });
  }

  const turmas = utils.getTurmasTreeView({
    turmas: serie?.turmas,
    somenteDescritivos: descritivos,
  });

  let turma: IFiltroTurma | undefined = undefined;
  if (filter.idTurma || !options.turma?.mostrarTodos) {
    turma = utils.getTurmaTreeView({
      type: usuario?.tipoUsuario,
      cursos,
      idCurso: filter.idCurso?.toString() ?? null,
      idSerie: filter.idSerie?.toString() ?? null,
      idTurma: filter.idTurma?.toString() ?? null,
    });
  }

  const materias = utils.getMateriasTreeView({
    serie,
    turma,
    somenteDescritivos: descritivos,
    sort: true,
  });
  const materia = materias.find((f) => f.id == filter.idMateria);

  let etapas: IPeriodoEtapa[] | undefined = undefined;
  if (curso) {
    etapas = todasEtapas.filter((etapa) => {
      return (
        etapa.idsCursos?.includes(curso?.id ?? 0) &&
        etapa.etapa <= configuracoes.bim_site
      );
    });
  }
  let etapa: IPeriodoEtapa | undefined = undefined;
  if (etapas) {
    etapa = etapas.find((etapa) => etapa.etapa == filter.etapa);
  }

  let minDate: Date | undefined = undefined;
  let maxDate: Date | undefined = undefined;
  if (options.data?.minDate) minDate = options.data?.minDate;
  if (options.data?.maxDate) maxDate = options.data?.maxDate;
  if (options.data?.etapaMinMax && options.etapa && etapa) {
    minDate = parseISO(etapa.dataEtapaIni ?? "");
    maxDate = parseISO(etapa.dataEtapaFim ?? "");
  }

  const navigate = useCallback(
    (params: IUseFilter & Query, path?: string) => {
      const queryParams: Record<string, Primitives> = {};
      queryKeys.map((key) => (queryParams[key as string] = params[key]));

      navi(path ?? pathname, queryParams as IUseFilter & Query);
    },
    [pathname, params]
  );

  useEffect(() => {
    if (serie?.idSistemaAvaliacao) {
      dispatch(getSistemaAvaliacao(serie.idSistemaAvaliacao));
    }
  }, [serie?.idSistemaAvaliacao, dispatch]);

  const CursoComponent = useCallback((): JSX.Element | undefined => {
    if (options.curso) {
      return (
        <FilterCurso
          mostrarTodos={options.curso.mostrarTodos ?? false}
          render={render}
          selecionado={curso}
          cursos={cursos}
          onClick={(id) =>
            options.curso?.onClick(id, { navigate, params, filter })
          }
        />
      );
    }
  }, [filter, cursos, curso, params]);

  const SerieComponent = useCallback((): JSX.Element | undefined => {
    if (options.serie) {
      return (
        <FilterSerie
          mostrarTodos={options.serie.mostrarTodos ?? false}
          render={render}
          selecionado={serie}
          series={series}
          onClick={(id) =>
            options.serie?.onClick(id, { navigate, params, filter })
          }
        />
      );
    }
  }, [filter, curso, serie, params]);

  const TurmaComponent = useCallback((): JSX.Element | undefined => {
    if (options.turma) {
      return (
        <FilterTurma
          mostrarTodos={options.turma.mostrarTodos ?? false}
          render={render}
          selecionado={turma}
          turmas={turmas}
          onClick={(id) =>
            options.turma?.onClick(id, { navigate, params, filter })
          }
        />
      );
    }
  }, [filter, serie, turma, params]);

  const MateriaComponent = useCallback((): JSX.Element | undefined => {
    if (options.materia) {
      return (
        <FilterMateria
          mostrarTodos={options.materia.mostrarTodos ?? false}
          render={render}
          selecionado={materia}
          materias={materias}
          onClick={(id) =>
            options.materia?.onClick(id, { navigate, params, filter })
          }
        />
      );
    }
  }, [filter, materias, materia, params]);

  const EtapaComponent = useCallback((): JSX.Element | undefined => {
    if (options.etapa && sistemaAvaliacao.data && !sistemaAvaliacao.loading) {
      return (
        <FilterEtapa
          mostrarTodos={options.etapa.mostrarTodos ?? false}
          render={render}
          selecionado={etapa}
          etapas={etapas}
          sistemaAvaliacao={sistemaAvaliacao.data}
          onClick={(etapa) =>
            options.etapa?.onClick(etapa, { navigate, params, filter })
          }
        />
      );
    }
  }, [filter, etapas, etapa, params]);

  const DataComponent = useCallback((): JSX.Element | undefined => {
    if (options.data) {
      return (
        <FilterData
          render={render}
          selecionado={filter.data}
          onClick={(data) =>
            options.data?.onClick(data, { navigate, params, filter })
          }
          minDate={minDate}
          maxDate={maxDate}
          allowsToClean={options.data.allowsToClean}
        />
      );
    }
  }, [filter, filter.data, minDate, maxDate, params]);

  const DataIniComponent = useCallback((): JSX.Element | undefined => {
    if (options.dataIni) {
      return (
        <FilterData
          label="Data Inicial"
          render={render}
          selecionado={filter.dataIni}
          onClick={(data) =>
            options.dataIni?.onClick(data, { navigate, params, filter })
          }
          minDate={minDate}
          maxDate={maxDate}
          dataFim={filter.dataFim}
          allowsToClean={options.dataIni.allowsToClean}
        />
      );
    }
  }, [filter, filter.dataIni, filter.dataFim, minDate, maxDate, params]);

  const DataFimComponent = useCallback((): JSX.Element | undefined => {
    if (options.dataFim) {
      return (
        <FilterData
          label="Data Final"
          render={render}
          selecionado={filter.dataFim}
          onClick={(data) =>
            options.dataFim?.onClick(data, { navigate, params, filter })
          }
          minDate={minDate}
          maxDate={maxDate}
          dataIni={filter.dataIni}
          allowsToClean={options.dataFim.allowsToClean}
        />
      );
    }
  }, [filter, filter.dataIni, filter.dataFim, minDate, maxDate, params]);

  const Component = useCallback((): JSX.Element => {
    return (
      <>
        {options.data && (
          <FilterData
            render={render}
            selecionado={filter.data}
            onClick={(data) =>
              options.data?.onClick(data, { navigate, params, filter })
            }
            minDate={minDate}
            maxDate={maxDate}
            allowsToClean={options.data.allowsToClean}
          />
        )}
        {options.dataIni && (
          <FilterData
            label="Data Inicial"
            render={render}
            selecionado={filter.dataIni}
            onClick={(data) =>
              options.dataIni?.onClick(data, { navigate, params, filter })
            }
            minDate={minDate}
            maxDate={maxDate}
            dataFim={filter.dataFim}
            allowsToClean={options.dataIni.allowsToClean}
          />
        )}
        {options.dataFim && (
          <FilterData
            label="Data Final"
            render={render}
            selecionado={filter.dataFim}
            onClick={(data) =>
              options.dataFim?.onClick(data, { navigate, params, filter })
            }
            minDate={minDate}
            maxDate={maxDate}
            dataIni={filter.dataIni}
            allowsToClean={options.dataFim.allowsToClean}
          />
        )}
        {options.curso && (
          <FilterCurso
            mostrarTodos={options.curso.mostrarTodos ?? false}
            render={render}
            selecionado={curso}
            cursos={cursos}
            onClick={(id) =>
              options.curso?.onClick(id, { navigate, params, filter })
            }
          />
        )}
        {options.serie && (
          <FilterSerie
            mostrarTodos={options.serie.mostrarTodos ?? false}
            render={render}
            selecionado={serie}
            series={series}
            onClick={(id) =>
              options.serie?.onClick(id, { navigate, params, filter })
            }
          />
        )}
        {options.turma && (
          <FilterTurma
            mostrarTodos={options.turma.mostrarTodos ?? false}
            render={render}
            selecionado={turma}
            turmas={turmas}
            onClick={(id) =>
              options.turma?.onClick(id, { navigate, params, filter })
            }
          />
        )}
        {options.materia && (
          <FilterMateria
            mostrarTodos={options.materia.mostrarTodos ?? false}
            render={render}
            selecionado={materia}
            materias={materias}
            onClick={(id) =>
              options.materia?.onClick(id, { navigate, params, filter })
            }
          />
        )}
        {options.etapa &&
          sistemaAvaliacao.data &&
          !sistemaAvaliacao.loading && (
            <FilterEtapa
              mostrarTodos={options.etapa.mostrarTodos ?? false}
              render={render}
              selecionado={etapa}
              etapas={etapas}
              sistemaAvaliacao={sistemaAvaliacao.data}
              onClick={(etapa) =>
                options.etapa?.onClick(etapa, { navigate, params, filter })
              }
            />
          )}
      </>
    );
  }, [
    filter,
    curso,
    serie,
    turma,
    materia,
    materias,
    etapa,
    filter.data,
    minDate,
    maxDate,
    params,
  ]);

  if (curso && !filter.idCurso) filter.idCurso = curso.id;
  if (serie && !filter.idSerie) filter.idSerie = serie.id;
  if (turma && !filter.idTurma) filter.idTurma = turma.id;
  if (materia && !filter.idMateria) filter.idMateria = materia.id;
  if (etapa && !filter.etapa) filter.etapa = etapa.etapa;

  return {
    selecionados: {
      curso,
      serie,
      turma,
      materia,
      etapa,
    },
    listas: {
      cursos,
      etapas: etapas ?? [],
      materias,
      series,
      turmas,
    },
    Component,
    CursoComponent,
    SerieComponent,
    TurmaComponent,
    MateriaComponent,
    EtapaComponent,
    DataComponent,
    DataIniComponent,
    DataFimComponent,
    navigate,
    filter,
    params,
  };
};
