import React, { FC } from "react";

import { Zoom, Fab, Tooltip } from "@mui/material";

import { Plus as AddIcon } from "mdi-material-ui";
import { useAppSelector } from "store";
import { Environment } from "configs";
import { useScroll } from "context/ScrollContext";

export const Float: FC<{
  onClick: () => void;
  title?: string;
  disabled?: boolean;
}> = ({ onClick, title = "Adicionar", disabled = false }) => {
  const { moving } = useScroll();

  const rightbarIsOpen = useAppSelector(
    (state) => state.layout.rightbar.isOpen
  );

  if (disabled) return <></>;

  return (
    <Zoom in={!moving} timeout={500} unmountOnExit>
      <Tooltip title={title}>
        <Fab
          sx={({ spacing }) => ({
            position: "fixed",
            bottom: spacing(2),
            right: !rightbarIsOpen
              ? spacing(2)
              : spacing(Environment.DRAWER_WIDTH + 2),
            backgroundColor: "rgba(255, 0, 0, 0.5)",
          })}
          onClick={onClick}
          color="secondary"
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </Zoom>
  );
};
