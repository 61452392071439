import React, { FC } from "react";
import { IUserChat } from "@deltasge/marauders-map";

import { ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Avatar } from "components/Avatar";

export interface IUserComGrupo extends IUserChat {
  grupo: string;
}

export const UserListItem: FC<{
  user: IUserComGrupo;
  props: React.HTMLAttributes<HTMLLIElement>;
}> = ({ user, props }) => (
  <ListItem {...props} key={user.deltaId}>
    <ListItemAvatar>
      <Avatar displayName={user.displayName ?? undefined} src={user.photoUrl} />
    </ListItemAvatar>
    <ListItemText primary={user.nome} secondary={user.detalhes} />
  </ListItem>
);
