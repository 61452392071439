import React, { FC, useCallback, useEffect, useState } from "react";

import { Collapse, Divider, LinearProgress, List } from "@mui/material";

import { api, apiConfig } from "configs";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import { useAppSelector } from "store";
import { IAluno, IUser } from "@deltasge/marauders-map";
import {
  IAlunoDeltaID,
  ListItemAlunoDoResponsavel,
} from "./ListItemAlunoDoResponsavel";

export const ListaAlunosDoResponsavel: FC<{
  idCliente: number;
  open: boolean;
  idSetor?: string | null;
}> = ({ idCliente, open, idSetor }) => {
  const escola = useAppSelector((state) => state.escola);
  const [alunos, setAlunos] = useState<IAlunoDeltaID[]>([]);
  const [loading, setLoading] = useState(false);

  const getAlunos = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get<IAluno[]>(
        `/aluno/do-responsavel/${idCliente}/ignora-matricula-ativa/1`
      );
      if (Array.isArray(data)) {
        const alunos = data.map((m) => {
          return {
            ...m,
            deltaId: `${escola.codigo}-A-${m.codigo}`,
          };
        }) as IAlunoDeltaID[];
        const { data: users } = await apiConfig.get<IUser[]>("v2/usuarios", {
          params: {
            where: {
              deltaId: { $in: alunos.map((m) => m.deltaId) },
            },
            select: ["deltaId", "photoUrl"],
          },
        });
        setAlunos(
          alunos.map((aluno) => {
            const u = users.find((f) => (f.deltaId = aluno.deltaId));
            if (aluno.imagemAluno?.caminhoArquivo) {
              aluno.photoUrl = aluno.imagemAluno?.caminhoArquivo;
            } else if (u?.photoUrl) {
              aluno.photoUrl = u.photoUrl;
            }
            return aluno;
          })
        );
      }
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open && alunos.length == 0) {
      getAlunos();
    }
  }, [open, alunos.length]);

  if (loading) return <LinearProgress />;

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding dense>
        {alunos.map((aluno) => (
          <ListItemAlunoDoResponsavel
            key={aluno.uid}
            aluno={aluno}
            idSetor={idSetor}
          />
        ))}
      </List>
      <Divider />
    </Collapse>
  );
};
