import React, { Dispatch, FC, SetStateAction } from "react";
import { Card } from "./Card";
import { IconButton, LinearProgress, TextField } from "@mui/material";

import { OpenInNew as OpenIcon } from "mdi-material-ui";
import { IMensbol, INotaDis, ITurmaDis } from "@deltasge/marauders-map";
import { useDialog } from "hooks/useDialog";
import { DialogMensagemBoletim } from "./DialogMensagemBoletim";

export const MensagemBoletim: FC<{
  mensBol?: IMensbol;
  loading?: boolean;
  setDescritiva: Dispatch<
    SetStateAction<
      | {
          mensBol?: IMensbol;
          turmaDis?: ITurmaDis;
          notaDis?: INotaDis;
        }
      | undefined
    >
  >;
  errors:
    | {
        mensBol?: string;
        turmaDis?: string;
        notaDis?: string;
      }
    | undefined;
}> = ({ mensBol, loading = false, setDescritiva, errors }) => {
  const { RenderDialog, show, hide } = useDialog({
    title: "Frente do boletim",
    options: {
      modal: true,
      maxWidth: "md",
      autoWidth: true,
    },
  });

  return (
    <Card
      cardProps={{ variant: "outlined", sx: { height: "21%", mb: 1 } }}
      title="Frente do boletim"
      action={
        <IconButton size="small" onClick={show}>
          <OpenIcon />
        </IconButton>
      }
    >
      <RenderDialog>
        {mensBol && (
          <DialogMensagemBoletim
            mensBol={mensBol}
            onClose={(mensagem) => {
              setDescritiva((p) => ({
                ...p,
                mensBol: { ...mensBol, mensagem },
              }));
              hide();
            }}
          />
        )}
      </RenderDialog>
      {loading && <LinearProgress />}
      {!loading && (
        <TextField
          label="Comunicado da turma"
          multiline
          disabled={mensBol == undefined}
          size="small"
          inputProps={{
            style: { height: "100%", overflow: "auto", fontSize: "14px" },
          }}
          error={!!errors?.mensBol}
          helperText={errors?.mensBol}
          value={mensBol?.mensagem || ""}
          sx={{
            width: "100%",
            maxHeight: "100%",
            height: "100%",
            "& .MuiInputBase-root": {
              height: "100%",
            },
          }}
          onChange={
            mensBol
              ? ({ target: { value } }) => {
                  setDescritiva((p) => ({
                    ...p,
                    mensBol: { ...mensBol, mensagem: value },
                  }));
                }
              : undefined
          }
        />
      )}
    </Card>
  );
};
