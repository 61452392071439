import React, { FC, useCallback, useEffect, useState } from "react";
import {
  IMatricula,
  IMensbol,
  INotaDis,
  ITurma,
  ITurmaDis,
  utils,
} from "@deltasge/marauders-map";
import { Button, ButtonGroup, Paper } from "@mui/material";
import { Filter as FilterIcon } from "mdi-material-ui";

import { api } from "configs";
import { useFilter } from "hooks/useFilter";
import { IFilterDescritiva } from "interfaces";
import { Main } from "pages/_layouts/Main";
import { useAppSelector } from "store";
import { snack } from "components/GlobalSnackbar";
import { getError } from "utils";
import {
  Filter,
  MensagemBoletim,
  NotaDescritiva,
  TurmaDescritiva,
} from "./components";

import { LoadingButton } from "@mui/lab";

export const Descritivas: FC = () => {
  const [matriculas, setMatriculas] = useState<IMatricula[]>([]);

  const [descritiva, setDescritiva] = useState<{
    mensBol?: IMensbol;
    turmaDis?: ITurmaDis;
    notaDis?: INotaDis;
  }>();

  const [loading, setLoading] = useState({
    mensBol: false,
    turmaDis: false,
    notaDis: false,
  });

  const [errors, setErrors] = useState<{
    mensBol?: string;
    turmaDis?: string;
    notaDis?: string;
  }>();

  const [saving, setSaving] = useState(false);

  const {
    escola,
    configuracoes: {
      configOnline: { configuracoes },
    },
  } = useAppSelector((state) => state);

  const handleFilterCurso = (idCurso?: number) =>
    navigate({
      ...filter,
      idCurso,
      idSerie: undefined,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterSerie = (idSerie?: number) =>
    navigate({
      ...filter,
      idSerie,
      idTurma: undefined,
      idMateria: undefined,
    });

  const handleFilterTurma = (idTurma?: number) =>
    navigate({
      ...filter,
      idTurma,
      idMateria: undefined,
    });

  const handleFilterMateria = (idMateria?: number) =>
    navigate({
      ...filter,
      idMateria,
    });

  const handleFilterEtapa = (etapa?: number) =>
    navigate({
      ...filter,
      etapa: etapa ?? configuracoes.bim_site,
    });

  const handleFilterMatricula = (idAluno?: number) =>
    navigate({ ...filter, idAluno });

  const handleMensagemBoletim = useCallback(
    async ({
      idTurma,
      bimestre,
      idCurso,
      idSerie,
    }: {
      idCurso: number;
      idSerie: number;
      idTurma: number;
      bimestre: number;
    }) => {
      try {
        setLoading((f) => ({ ...f, mensBol: true }));
        const { data } = await api.get<[IMensbol[], number]>(
          "mensagem-boletim",
          {
            params: {
              where: {
                idTurma,
                bimestre,
              },
            },
          }
        );
        let mensBol: IMensbol = {
          bimestre,
          idTurma,
          idCurso,
          idSerie,
          anoLetivo: escola.anoSite,
          mensagem: "",
        };
        if (
          Array.isArray(data) &&
          Array.isArray(data[0]) &&
          data[0].length > 0
        ) {
          mensBol = data[0][0];
        }
        setDescritiva((p) => ({
          ...p,
          mensBol,
        }));
      } catch (error) {
        snack.error(getError(error));
      } finally {
        setLoading((f) => ({ ...f, mensBol: false }));
      }
    },
    [escola.anoSite]
  );

  const handleTurmaDescritiva = useCallback(
    async ({
      bimestre,
      idMateria,
      idTurma,
      idSerie,
      idCurso,
    }: {
      bimestre: number;
      idMateria: number;
      idTurma: number;
      idSerie: number;
      idCurso: number;
    }) => {
      try {
        setLoading((p) => ({ ...p, turmaDis: true }));
        const { data } = await api.get<[ITurmaDis[], number]>(
          "turma-distritivo",
          {
            params: {
              idTurma,
              bimestre,
              idMateria,
            },
          }
        );
        let turmaDis: ITurmaDis = {
          anoLetivo: escola.anoSite,
          descricao: "",
          bimestre,
          idCurso,
          idSerie,
          idTurma,
          idMateria,
        };
        if (
          Array.isArray(data) &&
          Array.isArray(data[0]) &&
          data[0].length > 0
        ) {
          turmaDis = data[0][0];
        }
        setDescritiva((p) => ({ ...p, turmaDis }));
      } catch (error) {
        snack.error(getError(error));
      } finally {
        setLoading((p) => ({ ...p, turmaDis: false }));
      }
    },
    [escola.anoSite]
  );

  const handleNotaDescritiva = useCallback(
    async ({
      bimestre,
      idAluno,
      idMateria,
      idCurso,
    }: {
      bimestre: number;
      idMateria: number;
      idAluno: number;
      idCurso: number;
    }) => {
      try {
        setLoading((p) => ({ ...p, notaDis: true }));
        const { data } = await api.get<[INotaDis[], number]>(
          "nota-discritiva",
          {
            params: {
              anoLetivo: escola.anoSite,
              idAluno,
              bimestre,
              idMateria,
              idCurso,
            },
          }
        );
        let notaDis: INotaDis = {
          anoLetivo: escola.anoSite,
          descricao: "",
          bimestre,
          idCurso,
          idMateria,
          idAluno,
        };
        if (
          Array.isArray(data) &&
          Array.isArray(data[0]) &&
          data[0].length > 0
        ) {
          notaDis = data[0][0];
        }
        setDescritiva((p) => ({ ...p, notaDis }));
      } catch (error) {
        snack.error(getError(error));
      } finally {
        setLoading((p) => ({ ...p, notaDis: false }));
      }
    },
    [escola.anoSite]
  );

  const handleSave = async () => {
    if (!descritiva) {
      setErrors({
        mensBol: "É necessário informar a descrição da frente do boletim",
        turmaDis: "É necessário informar o objetivo da matéria",
        notaDis: "É necessário informar a avaliação descritiva do aluno",
      });
      return;
    } else if (!descritiva.mensBol || !descritiva.mensBol.mensagem) {
      setErrors({
        mensBol: "É necessário informar a descrição da frente do boletim",
      });
      return;
    } else if (!descritiva.turmaDis || !descritiva.turmaDis.descricao) {
      setErrors({
        turmaDis: "É necessário informar o objetivo da matéria",
      });
      return;
    } else if (!descritiva.notaDis || !descritiva.notaDis.descricao) {
      setErrors({
        notaDis: "É necessário informar a avaliação descritiva do aluno",
      });
      return;
    } else {
      setErrors({});
    }

    try {
      setSaving(true);
      const { mensBol, turmaDis, notaDis } = descritiva;

      const paramsMensBol = {
        bimestre: mensBol.bimestre,
        idTurma: mensBol.idTurma,
        idCurso: mensBol.idCurso,
        idSerie: mensBol.idSerie,
        anoLetivo: mensBol.anoLetivo,
        mensagem: mensBol.mensagem,
      };

      const paramsTurmaDis = {
        anoLetivo: turmaDis.anoLetivo,
        descricao: turmaDis.descricao,
        bimestre: turmaDis.bimestre,
        idCurso: turmaDis.idCurso,
        idSerie: turmaDis.idSerie,
        idTurma: turmaDis.idTurma,
        idMateria: turmaDis.idMateria,
      };

      const paramsNotaDis = {
        anoLetivo: notaDis.anoLetivo,
        descricao: notaDis.descricao,
        bimestre: notaDis.bimestre,
        idCurso: notaDis.idCurso,
        idMateria: notaDis.idMateria,
        idAluno: notaDis.idAluno,
      };

      if (mensBol.id) {
        const { data } = await api.put<IMensbol>(
          `mensagem-boletim/${mensBol.id}`,
          paramsMensBol
        );
        setDescritiva((p) => ({ ...p, mensBol: data }));
      } else {
        const { data } = await api.post<IMensbol>(
          "mensagem-boletim",
          paramsMensBol
        );
        setDescritiva((p) => ({ ...p, mensBol: data }));
      }

      if (turmaDis.id) {
        const { data } = await api.put<ITurmaDis>(
          `turma-distritivo/${turmaDis.id}`,
          paramsTurmaDis
        );
        setDescritiva((p) => ({ ...p, turmaDis: data }));
      } else {
        const { data } = await api.post<ITurmaDis>(
          "turma-distritivo",
          paramsTurmaDis
        );
        setDescritiva((p) => ({ ...p, turmaDis: data }));
      }

      if (notaDis.id) {
        const { data } = await api.put<INotaDis>(
          `nota-discritiva/${notaDis.id}`,
          paramsNotaDis
        );
        setDescritiva((p) => ({ ...p, notaDis: data }));
      } else {
        const { data } = await api.post<INotaDis>(
          "nota-discritiva",
          paramsNotaDis
        );
        setDescritiva((p) => ({ ...p, notaDis: data }));
      }
      snack.success("Avaliação salva com sucesso!");
    } catch (error) {
      snack.error(getError(error));
    } finally {
      setSaving(false);
    }
  };

  const {
    Component,
    navigate,
    filter,
    selecionados: { materia },
    listas: { materias },
  } = useFilter<IFilterDescritiva>({
    descritivos: true,
    pathname: "/descritivas/",
    queryKeys: [
      "idCurso",
      "idSerie",
      "idTurma",
      "idMateria",
      "etapa",
      "idAluno",
    ],
    initialValue: {
      etapa: configuracoes.bim_site,
    },
    options: {
      curso: {
        onClick: handleFilterCurso,
      },
      serie: {
        onClick: handleFilterSerie,
      },
      turma: {
        onClick: handleFilterTurma,
      },
      materia: {
        onClick: handleFilterMateria,
      },
      etapa: {
        onClick: handleFilterEtapa,
      },
    },
  });

  useEffect(() => {
    (async () => {
      try {
        if (filter.idTurma) {
          const { data } = await api.get<ITurma | undefined>(
            `turma/${filter.idTurma}`,
            {
              cache: {
                ignoreCache: true,
              },
              params: {
                select: [
                  "id",
                  "matriculas.id",
                  "matriculas.nrChamada",
                  "matriculas.situacao",
                  "matriculas.aluno.nome",
                  "matriculas.aluno.nomeusual",
                  "matriculas.aluno.uid",
                  "matriculas.aluno.codigo",
                  "matriculas.aluno.imagemAluno.caminhoArquivo",
                ],
                order: {
                  matriculas: { nrChamada: "ASC" },
                },
              },
            }
          );
          if (data && Array.isArray(data.matriculas)) {
            setMatriculas(data.matriculas);
          }
        }
      } catch (error) {
        snack.error(getError(error));
      }
    })();
  }, [filter.idTurma]);

  useEffect(() => {
    if (filter.idTurma && !filter.idMateria && materias.length > 0) {
      handleFilterMateria(materias.at(0)?.id);
    }
    if (filter.idTurma && filter.idCurso && filter.idSerie) {
      handleMensagemBoletim({
        idCurso: filter.idCurso,
        idSerie: filter.idSerie,
        idTurma: filter.idTurma,
        bimestre: filter.etapa,
      });
    }
    if (
      filter.idTurma &&
      filter.idCurso &&
      filter.idSerie &&
      filter.idMateria
    ) {
      handleTurmaDescritiva({
        bimestre: filter.etapa,
        idCurso: filter.idCurso,
        idMateria: filter.idMateria,
        idSerie: filter.idSerie,
        idTurma: filter.idTurma,
      });
    }
    if (filter.idAluno && filter.idCurso && filter.idMateria) {
      handleNotaDescritiva({
        bimestre: filter.etapa,
        idAluno: filter.idAluno,
        idCurso: filter.idCurso,
        idMateria: filter.idMateria,
      });
    }
  }, [
    filter.idCurso,
    filter.idSerie,
    filter.idTurma,
    filter.idMateria,
    filter.idAluno,
  ]);

  const handleVisualizar = useCallback(() => {
    if (filter.idAluno) {
      const params = utils.base64Encode(
        JSON.stringify({
          aluno: matriculas.find((f) => f.aluno?.uid == filter.idAluno)?.aluno
            ?.codigo,
          bimestre: filter.etapa,
          ano: escola.anoSite,
          professor: "000",
        })
      );
      let url = "a/boletim_descritivo.php?params=";
      if (escola.codigo == "0252") url = "a/boletim_instEduInf.php?params=";
      window.open(
        escola.onlineExt.concat(
          escola.onlineExt.endsWith("/") ? "" : "/",
          url,
          params
        )
      );
    }
  }, [filter.idAluno, escola.anoSite]);

  const matricula =
    filter.idAluno != undefined
      ? matriculas.find((f) => f.aluno?.uid == filter.idAluno)
      : undefined;

  return (
    <Main
      title="Avaliações descritivas"
      rightbarIcon={<FilterIcon />}
      rightbarChildren={
        <Filter
          Component={Component}
          filter={filter}
          matriculas={matriculas}
          handleFilterMatricula={handleFilterMatricula}
        />
      }
    >
      <MensagemBoletim
        mensBol={descritiva?.mensBol}
        loading={loading.mensBol}
        setDescritiva={setDescritiva}
        errors={errors}
      />

      <TurmaDescritiva
        setDescritiva={setDescritiva}
        loading={loading.turmaDis}
        turmaDis={descritiva?.turmaDis}
        materia={materia}
        materias={materias}
        handleFilterMateria={handleFilterMateria}
        errors={errors}
      />

      <NotaDescritiva
        matricula={matricula}
        matriculas={matriculas}
        handleFilterMatricula={handleFilterMatricula}
        loading={loading.notaDis}
        notaDis={descritiva?.notaDis}
        setDescritiva={setDescritiva}
        errors={errors}
      />

      <Paper
        variant="outlined"
        sx={({ spacing }) => ({
          height: spacing(4.7),
          mt: 1,
          textAlign: "right",
        })}
      >
        <ButtonGroup variant="text">
          <Button
            disabled={
              filter.idAluno == undefined || filter.idMateria == undefined
            }
            onClick={handleVisualizar}
          >
            Visualizar
          </Button>
          <LoadingButton
            disabled={
              filter.idAluno == undefined || filter.idMateria == undefined
            }
            color="secondary"
            onClick={handleSave}
            loading={saving}
          >
            Salvar
          </LoadingButton>
        </ButtonGroup>
      </Paper>
    </Main>
  );
};
