import React, { FC, useState } from "react";
import {
  ESituacaoMatricula,
  EUserType,
  IAlunoPhotoDeltaId,
  IMatricula,
  utils,
} from "@deltasge/marauders-map";

import {
  Badge,
  Checkbox,
  IconButton,
  ListItem as ListItemMUI,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  DotsVertical as MenuIcon,
  FolderStar as OcorrenciaIcon,
  Forum as ConversaIcon,
  ArrowRightThick as SituacaoIcon,
} from "mdi-material-ui";
import { history } from "configs";
import { Avatar } from "components/Avatar";
import { useAppSelector } from "store";
import { useDialog } from "hooks/useDialog";
import { snack } from "components/GlobalSnackbar";
import { useMobile } from "hooks/useMobile";

export const ListItem: FC<{
  matricula: IMatricula;
  divider: boolean;
  handleSelect: (matricula: IMatricula) => void;
  selecionadas: IMatricula[];
  handleOcorrencia: (matriculas: IMatricula[]) => void;
}> = ({ matricula, divider, handleSelect, selecionadas, handleOcorrencia }) => {
  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMd = useMobile("md");
  const {
    RenderDialog: SetorDialog,
    hide: hideSetorDialog,
    show: showSetorDialog,
  } = useDialog({
    title: "Iniciar uma conversa como:",
    options: {
      maxWidth: "xs",
    },
  });

  const handleConversa = (deltaId?: string) => {
    setAnchorEl(null);
    if (deltaId) {
      if (usuario?.tipoUsuario == EUserType.Professor) {
        history.push(`/chat/setor/professor/${deltaId}`);
      } else if (idSetores.length > 1) {
        showSetorDialog();
      } else if (idSetores.length == 1) {
        history.push(`/chat/setor/${idSetores[0].key}/${deltaId}`);
      } else {
        snack.warning("Seu usuário não tem nenhum perfil configurado");
      }
    }
  };

  const idSetores: { key: string; name: string }[] = [];
  if (usuario?.secretaria) {
    idSetores.push({ key: "secretaria", name: "Secretaria" });
  }
  if (usuario?.coordenacao) {
    idSetores.push({ key: "coordenacao", name: "Coordenação" });
  }
  if (usuario?.tesouraria) {
    idSetores.push({ key: "tesouraria", name: "Tesouraria" });
  }
  if (usuario?.diretoria) {
    idSetores.push({ key: "diretoria", name: "Diretoria" });
  }

  const deltaId = (matricula.aluno as IAlunoPhotoDeltaId).deltaId
    ? btoa((matricula.aluno as IAlunoPhotoDeltaId).deltaId)
    : undefined;

  const checked = selecionadas.find((f) => f.id == matricula.id) != undefined;

  return (
    <>
      <SetorDialog>
        {idSetores.map((m) => (
          <ListItemMUI key={m.key} disablePadding>
            <ListItemButton
              onClick={() => {
                hideSetorDialog();
                history.push(`/chat/setor/${m.key}/${deltaId}`);
              }}
            >
              <ListItemText primary={m.name} />
            </ListItemButton>
          </ListItemMUI>
        ))}
      </SetorDialog>
      <ListItemMUI
        divider={divider}
        disablePadding
        secondaryAction={
          <>
            {!isMd && (
              <>
                <Tooltip title="Criar ocorrência">
                  <IconButton onClick={() => handleOcorrencia([matricula])}>
                    <OcorrenciaIcon />
                  </IconButton>
                </Tooltip>
                {deltaId && (
                  <Tooltip title="Iniciar uma conversa">
                    <IconButton onClick={() => handleConversa(deltaId)}>
                      <ConversaIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
            {isMd && (
              <>
                <Tooltip title="Menu">
                  <IconButton
                    edge="end"
                    aria-label="more-vert"
                    onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      handleOcorrencia([matricula]);
                    }}
                  >
                    <ListItemIcon>
                      <OcorrenciaIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Criar ocorrência</ListItemText>
                  </MenuItem>
                  {deltaId && (
                    <MenuItem onClick={() => handleConversa(deltaId)}>
                      <ListItemIcon>
                        <ConversaIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Iniciar uma conversa</ListItemText>
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
          </>
        }
      >
        <ListItemButton dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              onChange={() => handleSelect(matricula)}
              checked={checked}
              tabIndex={-1}
              disableRipple
              inputProps={{
                "aria-labelledby": "aluno_" + matricula.nrChamada,
              }}
            />
          </ListItemIcon>
          <ListItemAvatar>
            <Badge
              overlap="circular"
              badgeContent={
                !utils.matriculaAtiva(
                  matricula.situacao as ESituacaoMatricula
                ) ? (
                  <SituacaoIcon color="secondary" />
                ) : undefined
              }
            >
              <Avatar
                key={"avatar_" + matricula.id}
                displayName={matricula.aluno?.nome}
                src={
                  matricula.aluno?.imagemAluno?.caminhoArquivo ??
                  (matricula.aluno as IAlunoPhotoDeltaId).photoUrl
                }
              />
            </Badge>
          </ListItemAvatar>
          <ListItemText
            id={"aluno_" + matricula.nrChamada}
            primary={`${
              matricula.nrChamada
            } - ${matricula.aluno?.nome?.rUpper()}`}
            secondary={matricula.historico}
          />
        </ListItemButton>
      </ListItemMUI>
    </>
  );
};
