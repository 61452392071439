import React, { Dispatch, FC, SetStateAction } from "react";
import { IconButton, LinearProgress, TextField } from "@mui/material";
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RigthIcon,
  OpenInNew as OpenIcon,
} from "mdi-material-ui";
import { Card } from "./Card";
import {
  IMatricula,
  IMensbol,
  INotaDis,
  ITurmaDis,
} from "@deltasge/marauders-map";
import { useDialog } from "hooks/useDialog";
import { DialogNotaDescritiva } from "./DialogNotaDescritiva";

export const NotaDescritiva: FC<{
  matricula?: IMatricula;
  matriculas: IMatricula[];
  handleFilterMatricula: (idAluno?: number) => void;
  notaDis?: INotaDis;
  loading?: boolean;
  setDescritiva: Dispatch<
    SetStateAction<
      | {
          mensBol?: IMensbol;
          turmaDis?: ITurmaDis;
          notaDis?: INotaDis;
        }
      | undefined
    >
  >;
  errors:
    | {
        mensBol?: string;
        turmaDis?: string;
        notaDis?: string;
      }
    | undefined;
}> = ({
  loading = false,
  matriculas,
  matricula,
  handleFilterMatricula,
  notaDis,
  setDescritiva,
  errors,
}) => {
  const index = matricula
    ? matriculas.findIndex((f) => f.aluno?.uid == matricula.aluno?.uid)
    : undefined;

  const title = matricula
    ? `${matricula?.nrChamada} - ${(
        matricula?.aluno?.nome ?? matricula?.aluno?.nomeusual
      )?.rUpper()}`
    : "Avaliação descritiva do aluno";

  const { RenderDialog, show, hide } = useDialog({
    title,
    options: {
      modal: true,
      maxWidth: "md",
      autoWidth: true,
    },
  });

  return (
    <Card
      cardProps={{
        variant: "outlined",
        sx: ({ spacing }) => ({ height: `calc(58% - ${spacing(7.7)})` }),
      }}
      title={title}
      action={
        <>
          <IconButton size="small" onClick={show}>
            <OpenIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={index == 0 || index == undefined}
            onClick={
              index != undefined
                ? () => {
                    handleFilterMatricula(matriculas.at(index - 1)?.aluno?.uid);
                  }
                : undefined
            }
          >
            <LeftIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={index == matriculas.length - 1 || index == undefined}
            onClick={
              index != undefined
                ? () => {
                    handleFilterMatricula(matriculas.at(index + 1)?.aluno?.uid);
                  }
                : undefined
            }
          >
            <RigthIcon />
          </IconButton>
        </>
      }
    >
      <RenderDialog>
        {notaDis && (
          <DialogNotaDescritiva
            notaDis={notaDis}
            onClose={(descricao) => {
              setDescritiva((p) => ({
                ...p,
                notaDis: { ...notaDis, descricao },
              }));
              hide();
            }}
          />
        )}
      </RenderDialog>
      {loading && <LinearProgress />}
      {!loading && (
        <TextField
          label="Avaliação descritiva do Aluno"
          multiline
          disabled={notaDis == undefined}
          size="small"
          error={!!errors?.notaDis}
          helperText={errors?.notaDis}
          inputProps={{
            style: { height: "100%", overflow: "auto", fontSize: "14px" },
          }}
          value={notaDis?.descricao ?? ""}
          sx={{
            width: "100%",
            maxHeight: "100%",
            height: "100%",
            overflow: "hidden",
            "& .MuiInputBase-root": {
              height: "100%",
            },
          }}
          onChange={
            notaDis
              ? ({ target: { value } }) =>
                  setDescritiva((p) => ({
                    ...p,
                    notaDis: { ...notaDis, descricao: value },
                  }))
              : undefined
          }
        />
      )}
    </Card>
  );
};
