import React, { FC, useState } from "react";
import {
  ESituacaoMatricula,
  IAluno,
  IAlunoPhotoDeltaId,
  IMatricula,
  utils,
} from "@deltasge/marauders-map";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  Alert,
  Badge,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ArrowRightThick as SituacaoIcon } from "mdi-material-ui";

import { snack } from "components/GlobalSnackbar";
import { Avatar } from "components/Avatar";

export const Alunos: FC<{
  handleClose: (alunos?: IAluno[]) => void;
  matriculas: IMatricula[];
}> = ({ handleClose, matriculas }) => {
  const [selecionados, setSelecionados] = useState<IMatricula[]>([]);

  const handleSelect = (m: IMatricula) => {
    if (selecionados.find((f) => f.id == m.id)) {
      setSelecionados((f) => [...f.filter((f) => f.id != m.id)]);
    } else {
      setSelecionados((f) => [...f, m]);
    }
  };

  const handleConfirm = () => {
    const alunos = selecionados
      .filter((f) => !!f.aluno)
      .map((m) => m.aluno) as IAluno[];
    if (alunos.length > 0) handleClose(alunos);
    else {
      snack.warning("Nenhum aluno encontrado");
    }
  };

  return (
    <>
      <PerfectScrollbar>
        <DialogContent dividers style={{ paddingBottom: 0 }}>
          {matriculas.length == 0 && (
            <Alert severity="info">Nenhum aluno encontrado!</Alert>
          )}
          {matriculas.length > 0 && (
            <List>
              {matriculas.map((m) => (
                <ListItem key={`aluno-matricula${m.id}`} disablePadding>
                  <ListItemButton dense onClick={() => handleSelect(m)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          selecionados.find((f) => f.id == m.id) != undefined
                        }
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": "aluno_matricula_" + m.nrChamada,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemAvatar>
                      <Badge
                        overlap="circular"
                        badgeContent={
                          !utils.matriculaAtiva(
                            m.situacao as ESituacaoMatricula
                          ) ? (
                            <SituacaoIcon color="secondary" />
                          ) : undefined
                        }
                      >
                        <Avatar
                          key={"matricula_aluno_avatar_" + m.id}
                          displayName={m.aluno?.nome}
                          src={
                            m.aluno?.imagemAluno?.caminhoArquivo ??
                            (m.aluno as IAlunoPhotoDeltaId).photoUrl
                          }
                        />
                      </Badge>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${m.nrChamada} - ${m.aluno?.nome?.rUpper()}`}
                      primaryTypographyProps={{
                        noWrap: true,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
      </PerfectScrollbar>
      <DialogActions>
        <Button onClick={() => handleClose()} variant="text" color="inherit">
          Cancelar
        </Button>
        <Button
          onClick={() => handleConfirm()}
          variant="text"
          disabled={selecionados.length == 0}
        >
          Criar
        </Button>
      </DialogActions>
    </>
  );
};
