import { IAluno } from "@deltasge/marauders-map";
import React, { FC } from "react";

import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useAppSelector } from "store";
import { Avatar } from "components/Avatar";
import { history } from "configs";

export interface IAlunoDeltaID extends IAluno {
  deltaId: string;
  photoUrl?: string;
}

export const ListItemAlunoDoResponsavel: FC<{
  aluno: IAlunoDeltaID;
  idSetor?: string | null;
}> = ({ aluno, idSetor }) => {
  const escola = useAppSelector((state) => state.escola);

  let matricula = aluno.matriculas?.find((f) => f.anoLetivo == escola.anoSite);
  if (!matricula) {
    matricula = aluno.matriculas?.at(0);
  }

  let descMatricula: string | undefined;
  if (matricula) {
    descMatricula = `${
      matricula.turma?.tituloTurma ? matricula.turma.tituloTurma : ""
    } - ${matricula.anoLetivo}`;
  }

  return (
    <ListItem>
      <ListItemButton
        onClick={
          idSetor
            ? () => {
                const deltaId = btoa(aluno.deltaId);
                history.push(`/chat/setor/${idSetor}/${deltaId}`);
              }
            : undefined
        }
      >
        <ListItemAvatar sx={{ display: "flex", justifyContent: "end", pr: 2 }}>
          <Avatar
            src={aluno.photoUrl}
            displayName={aluno.nomeusual || aluno.nome}
            sx={{ width: 36, height: 36 }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={aluno.nomeusual?.rUpper() || aluno.nome.rUpper()}
          secondary={descMatricula}
        />
      </ListItemButton>
    </ListItem>
  );
};
