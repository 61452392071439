import React, { FC } from "react";

import { Zoom as MUIZoom } from "@mui/material";
import { useScroll } from "context/ScrollContext";

export const ZoomOnScroll: FC<{
  children: React.ReactElement<any, any>;
}> = ({ children }) => {
  const { moving } = useScroll();

  return (
    <MUIZoom in={!moving} timeout={500} unmountOnExit>
      {children}
    </MUIZoom>
  );
};
