import React, { FC } from "react";
import Qs from "qs";
import { useHistory } from "react-router-dom";
import { IFiltroTurma } from "@deltasge/marauders-map";

import {
  ButtonGroup,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  MessageBulleted as MensagensIcon,
  Notebook as DiarioIcon,
  NotebookCheck as DiarioRapidoIcon,
  FormatListBulletedSquare as ConteudoIcon,
  FolderStar as OcorrenciaIcon,
  AccountMultipleCheck as ChamadaIcon,
  CalendarCheck as ChamadaDiaIcon,
  ClipboardClock as AvaliacaoIcon,
  TableCheck as NotasIcon,
} from "mdi-material-ui";

import { IParamsHandleTurma } from "interfaces";

const sPlural = (quantidade: number | null | undefined): string => {
  if (quantidade && quantidade > 1) return "s";
  return "";
};

export const Item: FC<{
  isProfessor: boolean;
  image: string;
  turma: IFiltroTurma;
  showImpressaoRapida: boolean;
  diarioDownPrint: number;
  handleTurma: ({
    turma,
    acao,
    impressaoRapida,
  }: IParamsHandleTurma) => Promise<void>;
}> = ({
  image,
  turma,
  handleTurma,
  showImpressaoRapida,
  diarioDownPrint,
  isProfessor,
}) => {
  const history = useHistory();

  const query = Qs.stringify({
    idCurso: turma.idCurso,
    idSerie: turma.idSerie,
    idTurma: turma.id,
  });

  return (
    <Grid item xs={12} sm={6} md={5} lg={4}>
      <Card>
        <CardActionArea onClick={() => history.push(`/turma/${turma.id}`)}>
          <CardMedia
            sx={{ height: ({ spacing }) => spacing(15) }}
            title={turma.tituloTurma}
            image={image}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {turma.tituloTurma}
            </Typography>
            <Typography variant="caption" color="text.secondary" component="p">
              {`Sala: ${
                turma.sala && turma.sala.trim() != ""
                  ? turma.sala
                  : "NÃO INFORMADO"
              } - Limite de alunos: ${turma.limiteSala}`}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="p"
            >
              {`${turma.totalEstudando} aluno${sPlural(
                turma.totalEstudando
              )} estudando`}
            </Typography>
            <Typography variant="caption" color="text.secondary" component="p">
              {`${turma.totalTransferido} aluno${sPlural(
                turma.totalTransferido
              )} tranferido${sPlural(turma.totalTransferido)}`}
            </Typography>
            <Typography variant="caption" color="text.secondary" component="p">
              {`${turma.totalDesistente} aluno${sPlural(
                turma.totalDesistente
              )} desistente${sPlural(turma.totalDesistente)}`}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <ButtonGroup>
            <Tooltip title="Aulas">
              <IconButton
                onClick={() =>
                  handleTurma({
                    turma,
                    acao: "aulas",
                  })
                }
              >
                <ChamadaIcon />
              </IconButton>
            </Tooltip>
            {!isProfessor && (
              <Tooltip title="Chamada dia">
                <IconButton
                  onClick={() =>
                    handleTurma({
                      turma,
                      acao: "chamadas",
                    })
                  }
                >
                  <ChamadaDiaIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Avaliações">
              <IconButton
                onClick={() =>
                  handleTurma({
                    turma,
                    acao: "avaliacao",
                  })
                }
              >
                <AvaliacaoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Conteúdo ministrado">
              <IconButton
                onClick={() =>
                  handleTurma({
                    turma,
                    acao: "conteudo",
                  })
                }
              >
                <ConteudoIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Diário de classe">
              <IconButton
                onClick={() =>
                  handleTurma({
                    turma,
                    acao: "diario",
                  })
                }
              >
                <DiarioIcon />
              </IconButton>
            </Tooltip>
            {showImpressaoRapida && (
              <Tooltip
                title={`${
                  diarioDownPrint == 1 ? "Download do" : "Visualizar"
                } diário de
                    classe`}
              >
                <IconButton
                  onClick={() =>
                    handleTurma({
                      turma,
                      acao: "diario",
                      impressaoRapida: true,
                    })
                  }
                >
                  <DiarioRapidoIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Digitação de notas">
              <IconButton
                onClick={() =>
                  handleTurma({
                    turma,
                    acao: "notas",
                  })
                }
              >
                <NotasIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Publicações">
              <IconButton
                onClick={() => history.push(`/publicacoes/?${query}`)}
              >
                <MensagensIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ocorrências">
              <IconButton
                onClick={() => history.push(`/ocorrencias/?${query}`)}
              >
                <OcorrenciaIcon />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </CardActions>
      </Card>
    </Grid>
  );
};
