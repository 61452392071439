import { INotaDis } from "@deltasge/marauders-map";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import React, { FC, useState } from "react";

export const DialogNotaDescritiva: FC<{
  onClose: (descricao: string) => void;
  notaDis: INotaDis;
}> = ({ onClose, notaDis }) => {
  const [descricao, setDescricao] = useState(notaDis.descricao ?? "");
  return (
    <>
      <DialogContent dividers style={{ paddingBottom: 0 }}>
        <TextField
          label="Comunicado da turma"
          multiline
          minRows={5}
          size="small"
          inputProps={{
            style: { height: "100%", overflow: "auto", fontSize: "14px" },
          }}
          value={descricao}
          sx={{
            width: "100%",
            maxHeight: "100%",
            height: "100%",
            overflow: "hidden",
            flexDirection: "row",
          }}
          onChange={({ target: { value } }) => setDescricao(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(descricao)}
          variant="text"
          color="inherit"
        >
          Fechar
        </Button>
      </DialogActions>
    </>
  );
};
