import React, { FC, useState } from "react";
import { EUserType, IChatGroup } from "@deltasge/marauders-map";

import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  ChevronDown as ExpandedIcon,
  ChevronUp as NoExpandedIcon,
} from "mdi-material-ui";

import { Avatar } from "components/Avatar";
import { useAppSelector } from "store";
import { history } from "configs";
import { ListaAlunosDoResponsavel } from "./ListaAlunosDoResponsavel";

export const ChatUsers: FC<{
  chatGroup: IChatGroup;
}> = ({ chatGroup }) => {
  const [openAlunos, setOpenAlunos] = useState(true);

  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);
  let setor = "Nenhum setor definido";

  if (chatGroup.idSetor == "secretaria") setor = "Secretaria";
  else if (chatGroup.idSetor == "coordenacao") setor = "Coordenação";
  else if (chatGroup.idSetor == "tesouraria") setor = "Tesouraria";
  else if (chatGroup.idSetor == "diretoria") setor = "Diretoria";
  else if (chatGroup.idSetor == "professor") setor = "Professor";

  return (
    <Box sx={{ width: "100%" }}>
      <Box p={2}>
        <Typography variant="caption">Setor</Typography>
        <Typography variant="body1" fontWeight="500">
          {setor}
        </Typography>
      </Box>
      <List
        dense
        disablePadding
        subheader={<ListSubheader>Participantes</ListSubheader>}
      >
        {(chatGroup.chatUsers ?? []).map((m) => {
          let secondary = "";
          const isMe = m.deltaId == usuario?.deltaId;
          if (m.tipoUsuario == EUserType.Aluno) secondary = "Aluno";
          else if (m.tipoUsuario == EUserType.LoginExtra)
            secondary = "Login Extra do Aluno";
          else if (m.tipoUsuario == EUserType.Professor)
            secondary = "Professor";
          else if (m.tipoUsuario == EUserType.Responsavel)
            secondary = "Responsável";
          else secondary = "Usuário do sistema";
          return (
            <>
              <ListItem
                key={m.deltaId}
                disableGutters
                disablePadding
                secondaryAction={
                  m.tipoUsuario == EUserType.Responsavel && m.id ? (
                    <Tooltip title="Ver alunos">
                      <IconButton onClick={() => setOpenAlunos(!openAlunos)}>
                        {openAlunos ? <NoExpandedIcon /> : <ExpandedIcon />}
                      </IconButton>
                    </Tooltip>
                  ) : undefined
                }
              >
                <ListItemButton
                  onClick={() => {
                    if (!isMe) {
                      const deltaId = btoa(m.deltaId);
                      history.push(
                        `/chat/setor/${chatGroup.idSetor}/${deltaId}`
                      );
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      displayName={m.displayName || m.nome}
                      src={m.photoUrl}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={isMe ? "Você" : m.displayName || m.nome}
                    secondary={secondary}
                  />
                </ListItemButton>
              </ListItem>
              {m.tipoUsuario == EUserType.Responsavel && m.id && (
                <ListaAlunosDoResponsavel
                  idCliente={m.id}
                  open={openAlunos}
                  idSetor={chatGroup.idSetor}
                />
              )}
            </>
          );
        })}
      </List>
    </Box>
  );
};
