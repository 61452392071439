import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom";
import { analytics } from "App";
import { useAppSelector } from "store";

export const useFirebaseRoutesAnalytics = (): null => {
  const location = useLocation();
  const { versao } = useAppSelector((state) => state);
  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: `${window.location.hostname}${location.pathname}`,
      firebase_screen_class: "firebase-routes-analytics",
      app_version: versao.app,
    });
  }, [location]);

  return null;
};
