import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format, isAfter, isToday, parseISO } from "date-fns";
import {
  EMensagemCategoriaTipo,
  EUserType,
  IAlunosChamada,
  IDiaAula,
  IFalta,
  IMensagem,
  IUser,
  yupDiaAula,
  yupMensagem,
  yupMensagemAtribuicao,
} from "@deltasge/marauders-map";
import { LinearProgress, List, Paper } from "@mui/material";
import { AccountCog as SettingIcon } from "mdi-material-ui";

import { api, Environment } from "configs";
import { snack } from "components/GlobalSnackbar";
import { Main } from "pages/_layouts/Main";
import { getError } from "utils";

import { ListItem } from "./components/ListItem";
import { DadosAula } from "./components/DadosAula";
import { useAppSelector, useAppDispatch } from "store";
import { request as getCategorias } from "store/modules/categorias";
import { request as getModelos } from "store/modules/relatorios/diarios/modelos";
import { request as getPapeis } from "store/modules/relatorios/configuracoes/papeis";
import { useSessionStorage } from "react-use";
import { utilMensagem } from "pages/Mensagens/utilMensagem";

const getMensagemDefault = ({
  aula,
  idMensagemCategoria,
  usuario,
}: {
  aula: IDiaAula;
  idMensagemCategoria: number;
  usuario: IUser | null;
}) => ({
  ...yupMensagem.getDefault(),
  texto: "",
  rascunho: false,
  idMensagemCategoria: idMensagemCategoria,
  idUsuario:
    usuario?.tipoUsuario == EUserType.Professor ? null : usuario?.id ?? null,
  idProfessor:
    usuario?.tipoUsuario == EUserType.Professor ? usuario?.id ?? null : null,
  mensagemAtribuicoes: [
    {
      ...yupMensagemAtribuicao.getDefault(),
      idCurso: aula.idCurso,
      idSerie: aula.idSerie,
      idTurma: aula.idTurma,
      idMateria: aula.idMateria,
      anoLetivo: aula.anoLetivo,
      aluno: true,
      excluido: false,
      exportado: false,
    },
  ],
});
export interface IChamada extends IAlunosChamada {
  selected: boolean;
}

export const GerenciarAula: FC = () => {
  const { idDiaAula } = useParams<{
    idDiaAula: string;
  }>();

  const {
    escola,
    usuario: { usuario },
    mensagemCategoria: { data: mensagemCategorias },
    modelosDiarios: { data: modelosDiarios },
    tamanhoPapeis: { data: tamanhoPapeis },
  } = useAppSelector((state) => state);

  const [aula, setAula] = useState<IDiaAula>();
  const [alunos, setAlunos] = useState<IChamada[]>([]);
  const [idMensagemCategoria, setIdMensagemCategoria] = useState(0);
  const [precisaNotificarTarefa, setPrecisaNotificarTarefa] = useState(false);
  const [textoAulaInicial, setTextoAulaInicial] = useState<string | null>("");
  const [textoTarefaInicial, setTextoTarefaInicial] = useState<string | null>(
    ""
  );
  const [loading, setLoading] = useState(false);
  const [msgErrorAttr, setMsgErrorAttr] = useState<string>();
  const [showTooltipTarefa, setShowTooltipTarefa] = useState(false);
  const [showTooltipAula, setShowTooltipAula] = useState(false);
  const [modificou, setModificou] = useState(false);

  const dispatch = useAppDispatch();

  const [conteudoCopiado, setConteudoCopiado] =
    useSessionStorage<string>("conteudo-copiado");

  useEffect(() => {
    if (mensagemCategorias.length == 0) {
      dispatch(getCategorias());
    }
    (async () => {
      try {
        setLoading(true);

        if (modelosDiarios.length == 0 || tamanhoPapeis.length == 0) {
          dispatch(getModelos());
          dispatch(getPapeis());
        }

        const { data: aula } = await api.get<IDiaAula | undefined>(
          `/diaaula/${idDiaAula}`,
          {
            params: {
              select: [
                ...Object.keys(yupDiaAula.fields),
                "anoLetivo",
                "curso.grau",
                "turma",
                "materia.nome",
                "materia.codigo",
                "usuario.nome",
                "professor.nome",
                ...utilMensagem.camposPublicacao("mensagem"),
              ],
            },
          }
        );
        if (aula) {
          if (!aula.mensagem) {
            const categoria = mensagemCategorias.find(
              (f) => f.tipo == EMensagemCategoriaTipo["Tarefas de Casa"]
            );
            if (categoria && categoria.id) {
              setIdMensagemCategoria(categoria.id);
              aula.mensagem = getMensagemDefault({
                aula,
                usuario,
                idMensagemCategoria: categoria.id,
              });
            }
          }
          if (aula.turma && !aula.turma.chamadaFixada) {
            api.put(`turma/${aula.turma.id}`, {
              ...aula.turma,
              chamadaFixada: true,
            });
          }
          setTextoTarefaInicial(aula.mensagem?.texto ?? null);
          setTextoAulaInicial(aula.descricao);
          setAula(aula);
        }

        const { data: alunos } = await api.get<IAlunosChamada[] | undefined>(
          `/educador/chamada/${idDiaAula}`
        );
        if (alunos) {
          setAlunos(alunos.map((m) => ({ ...m, selected: !m.idFalta })));
        }
      } catch (error) {
        snack.error(getError(error));
      } finally {
        setLoading(false);
      }
    })();
  }, [idDiaAula]);

  const gerarFalta = async (aluno: IChamada) => {
    try {
      setAlunos((alunos) =>
        alunos.map((m) => ({
          ...m,
          selected: m.idMatricula == aluno.idMatricula ? false : m.selected,
        }))
      );

      const { data: falta } = await api.post<IFalta | undefined>("falta", {
        anoLetivo: escola.anoSite,
        idDiaAula,
        idMatricula: aluno.idMatricula,
        id: null,
      });
      if (!falta) {
        throw new Error("Erro ao gerar a falta do aluno");
      }

      setAlunos((alunos) =>
        alunos.map((m) => ({
          ...m,
          idFalta: m.idMatricula == aluno.idMatricula ? falta.id : m.idFalta,
        }))
      );
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const apagarFalta = async (aluno: IChamada) => {
    try {
      if (!aluno.idFalta) return;
      setAlunos((alunos) =>
        alunos.map((m) => ({
          ...m,
          selected: m.idMatricula == aluno.idMatricula ? true : m.selected,
        }))
      );

      await api.delete(`falta/${aluno.idFalta}`);

      setAlunos((alunos) =>
        alunos.map((m) => ({
          ...m,
          idFalta: m.idMatricula == aluno.idMatricula ? null : m.idFalta,
        }))
      );
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const apagarTodasFaltas = async () => {
    try {
      const faltasId = (alunos ?? [])
        .filter((f) => f.idFalta)
        .map((m) => m.idFalta) as number[];
      if (faltasId?.length > 0) {
        setAlunos((alunos) =>
          alunos.map((m) => ({
            ...m,
            idFalta: null,
            selected: true,
          }))
        );

        await api.delete(`falta/${faltasId?.join(",")}`);
      }
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const gravarAula = async (aula: IDiaAula) => {
    try {
      let idTarefa: number | undefined = undefined;
      if (aula.diaSemAula && aula.idMensagem) {
        idTarefa = aula.idMensagem;
        aula.mensagem = getMensagemDefault({
          aula,
          usuario,
          idMensagemCategoria,
        });
        aula.idMensagem = null;
      }

      setAula(() => ({ ...aula }));

      if (aula.diaSemAula) {
        await apagarTodasFaltas();
      }

      await api.put(`diaaula/${aula.id}`, {
        aula: aula.aula,
        data: aula.data,
        descricao: aula.descricao,
        diaSemAula: aula.diaSemAula,
        excluido: aula.excluido,
        exportado: aula.exportado,
        id: aula.id,
        idCurso: aula.idCurso,
        idMateria: aula.idMateria,
        idMensagem: aula.idMensagem,
        idProfessor:
          usuario?.tipoUsuario == EUserType.Professor ? usuario?.id : null,
        idSerie: aula.idSerie,
        idTurma: aula.idTurma,
        idUsuario:
          usuario?.tipoUsuario == EUserType.Professor ? null : usuario?.id,
        inutilizaDia: aula.inutilizaDia,
        tarefa: aula.tarefa,
        anoLetivo: escola.anoSite,
      });
      setTextoAulaInicial(aula.descricao);
      setModificou(false);
      if (idTarefa) {
        await api.delete(`educador/mensagem/${idTarefa}`);
      }
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const removerTarefa = async () => {
    if (!aula) return;
    const idTarefa = aula.idMensagem;
    aula.mensagem = getMensagemDefault({
      aula,
      usuario,
      idMensagemCategoria,
    });
    await gravarAula({ ...aula, idMensagem: null });
    await api.delete(`educador/mensagem/${idTarefa}`);
  };

  const gravarTarefa = async (mensagem: IMensagem) => {
    try {
      if (!aula) return;

      setAula(() => ({ ...aula, mensagem }));

      if (mensagem && mensagem.texto == "" && !aula.idMensagem) {
        return;
      }
      if (mensagem && mensagem.texto == "" && aula.idMensagem) {
        await removerTarefa();
        return;
      }

      const att = mensagem?.mensagemAtribuicoes?.at(0);

      if (att) {
        if (!att.aluno && !att.responsavel && !att.loginExtra) {
          setMsgErrorAttr("Você deve escolher quem vai receber a tarefa");
          return;
        } else {
          setMsgErrorAttr(undefined);
        }
      }

      if (!mensagem.id) {
        const { data } = await api.post<IMensagem>(
          "educador/mensagem",
          mensagem
        );
        if (data) aula.mensagem = { ...mensagem, ...data };
        await gravarAula({ ...aula, idMensagem: data.id ?? null });
      } else {
        const { id, ...rest } = mensagem;
        const { data } = await api.put<IMensagem>(
          `educador/mensagem/${id}`,
          rest
        );
        if (data) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { mensagemArquivos, ...rest } = data;
          mensagem = {
            ...mensagem,
            ...rest,
          };
        }
        setAula(() => ({ ...aula, mensagem }));
      }
      setTextoTarefaInicial(mensagem.texto);
      setPrecisaNotificarTarefa(
        isAfter(parseISO(aula.data), Date.now()) || isToday(parseISO(aula.data))
      );
      setModificou(false);
    } catch (error) {
      snack.error(getError(error));
    }
  };

  const notificaTarefa = () => {
    if (usuario && usuario.escolaId && usuario.token) {
      const data: Record<string, string> = {
        token: usuario.token,
      };
      const params = new URLSearchParams(data).toString();
      navigator.sendBeacon(
        `${Environment.API_URL}/api/${usuario.escolaId}/educador/mensagem/notifica-tarefa-dia-aula/${idDiaAula}/?${params}`
      );
    }
    setPrecisaNotificarTarefa(false);
  };

  const alertUser = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = "Deseja fechar o gerenciamento da aula?");
  };

  useEffect(() => {
    return () => {
      if (precisaNotificarTarefa) notificaTarefa();
    };
  }, [precisaNotificarTarefa]);

  useEffect(() => {
    if (modificou) {
      window.addEventListener("beforeunload", alertUser);
    }
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [modificou]);

  useEffect(() => {
    return () => {
      (async () => {
        if (aula?.mensagem && textoTarefaInicial != aula.mensagem.texto) {
          setTextoTarefaInicial(aula.mensagem.texto);
          await gravarTarefa(aula.mensagem);

          setShowTooltipTarefa(true);
          window.setTimeout(() => setShowTooltipTarefa(false), 1000);
        }
        if (aula && textoAulaInicial != aula.descricao) {
          setTextoAulaInicial(aula.descricao);
          await gravarAula(aula);

          setShowTooltipAula(true);
          window.setTimeout(() => setShowTooltipAula(false), 1000);
        }
      })();
    };
  }, [textoAulaInicial, aula, textoTarefaInicial]);

  let dataAula: Date | undefined;
  if (aula?.data) {
    dataAula = parseISO(aula.data);
  }

  return (
    <Main
      loading={loading}
      title={aula?.turma?.tituloTurma ?? ""}
      subtitle={`Aula: ${aula?.aula} - ${aula?.materia?.nome} - ${
        dataAula ? format(dataAula, "dd/MM/yyyy") : ""
      }`}
      rightbarChildren={
        <>
          {!aula && <LinearProgress />}
          {aula && (
            <DadosAula
              aula={aula}
              gravarAula={gravarAula}
              gravarTarefa={gravarTarefa}
              setAula={setAula}
              mensagem={aula.mensagem}
              apagarTodasFaltas={apagarTodasFaltas}
              conteudoCopiado={conteudoCopiado}
              setConteudoCopiado={setConteudoCopiado}
              removerTarefa={removerTarefa}
              setMsgErrorAttr={setMsgErrorAttr}
              msgErrorAttr={msgErrorAttr}
              setTextoAulaInicial={setTextoAulaInicial}
              setTextoTarefaInicial={setTextoTarefaInicial}
              showTooltipAula={showTooltipAula}
              showTooltipTarefa={showTooltipTarefa}
              setModificou={setModificou}
              modificou={modificou}
            />
          )}
        </>
      }
      rightbarIcon={<SettingIcon />}
    >
      {dataAula && aula && alunos && (
        <List component={Paper}>
          {alunos.map((aluno, i, a) => (
            <ListItem
              key={"iAluno" + i}
              divider={i + 1 < a.length}
              aluno={aluno}
              diaAula={aula}
              apagarFalta={apagarFalta}
              gerarFalta={gerarFalta}
            />
          ))}
        </List>
      )}
    </Main>
  );
};
